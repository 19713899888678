const rules = {
    user: {
        static: [],
    },

    admin: {
        static: [
            "drawer-admin-items:view",
            "tickets-manager:showall",
            "user-modal:editProfile",
            "user-modal:editQueues",
            "ticket-options:deleteTicket",
            "contacts-page:deleteContact",
            "connections-page:actionButtons",
            "connections-page:addConnection",
            "ticket-options:transferWhatsapp",
            "connections-page:editOrDeleteConnection",
            "sign-message:disable",
        ],
    },
};

export default rules;
