import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AuthProvider } from "../context/Auth/AuthContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import LoggedInLayout from "../layout";
import Connections from "../pages/Connections/";
import Contacts from "../pages/Contacts/";
import Dashboard from "../pages/Dashboard/";
import Login from "../pages/Login/";
import Queues from "../pages/Queues/";
import QuickAnswers from "../pages/QuickAnswers/";
import Schedules from "../pages/Schedules/";
import SettingMessage from "../pages/SettingMessage";
import Settings from "../pages/Settings/";
import Signup from "../pages/Signup/";
import Tags from "../pages/Tags/";
import Tickets from "../pages/Tickets/";
import Users from "../pages/Users";
import Route from "./Route";
import Subscriptions from "../pages/Subscriptions/";
import Docs from "../pages/docs/";
import Swagger from "../pages/swagger/";
import Tokens from "../pages/tokens/";
import Chat from "../pages/Chat";
import DialogFlowTemplateBuilder from "../pages/DialogFlowTemplateBuilder";
import ContactLists from "../pages/ContactLists/";
import ContactListItems from "../pages/ContactListItems/";
import Campaigns from "../pages/Campaigns";
import CampaignsConfig from "../pages/CampaignsConfig";
import CampaignReport from "../pages/CampaignReport";
import Helps from "../pages/Helps/";
import {
    getApiRest,
    getCampaign,
    getDialogFlowIntegration,
    getInternalChat,
} from "../config";

const Routes = ({ loginLogo, dashLogo, themeToggle, themeDefault }) => {
    return (
        <BrowserRouter>
            <AuthProvider>
                <Switch>
                    <Route
                        exact
                        path="/login"
                        component={() => <Login loginLogo={loginLogo} />}
                    />
                    <Route exact path="/signup" component={Signup} />
                    <WhatsAppsProvider>
                        <LoggedInLayout
                            themeToggle={themeToggle}
                            dashLogo={dashLogo}
                            loginLogo={loginLogo}
                        >
                            <Route
                                exact
                                path="/"
                                component={() => (
                                    <Dashboard themeDefault={themeDefault} />
                                )}
                                isPrivate
                            />
                            <Route
                                exact
                                path="/tickets/:ticketId?"
                                component={() => (
                                    <Tickets loginLogo={loginLogo} />
                                )}
                                isPrivate
                            />
                            <Route
                                exact
                                path="/connections"
                                component={Connections}
                                isPrivate
                            />
                            <Route
                                exact
                                path="/contacts"
                                component={Contacts}
                                isPrivate
                            />
                            <Route
                                exact
                                path="/helps"
                                component={Helps}
                                isPrivate
                            />
                            <Route
                                exact
                                path="/users"
                                component={Users}
                                isPrivate
                            />
                            <Route
                                exact
                                path="/subscriptions"
                                component={Subscriptions}
                                isPrivate
                            />
                            <Route
                                exact
                                path="/quickAnswers"
                                component={QuickAnswers}
                                isPrivate
                            />
                            <Route
                                exact
                                path="/Settings"
                                component={Settings}
                                isPrivate
                            />
                            {getApiRest() === 1 && (
                                <>
                                    <Route
                                        exact
                                        path="/docs"
                                        component={Docs}
                                        isPrivate
                                    />
                                    <Route
                                        exact
                                        path="/swagger"
                                        component={Swagger}
                                        isPrivate
                                    />
                                </>
                            )}
                            <Route
                                exact
                                path="/tokens"
                                component={Tokens}
                                isPrivate
                            />
                            <Route
                                exact
                                path="/Queues"
                                component={Queues}
                                isPrivate
                            />
                            <Route
                                exact
                                path="/tags"
                                component={Tags}
                                isPrivate
                            />
                            {getInternalChat() === 1 && (
                                <>
                                    <Route
                                        exact
                                        path="/chats/:id?"
                                        component={Chat}
                                        isPrivate
                                    />
                                </>
                            )}
                            <Route
                                exact
                                path="/schedules"
                                component={Schedules}
                                isPrivate
                            />
                            {getCampaign() === 1 && (
                                <>
                                    <Route
                                        exact
                                        path="/SettingsMessage"
                                        component={SettingMessage}
                                        isPrivate
                                    />

                                    <Route
                                        exact
                                        path="/contact-lists"
                                        component={ContactLists}
                                        isPrivate
                                    />
                                    <Route
                                        exact
                                        path="/contact-lists/:contactListId/contacts"
                                        component={ContactListItems}
                                        isPrivate
                                    />
                                    <Route
                                        exact
                                        path="/campaigns"
                                        component={Campaigns}
                                        isPrivate
                                    />
                                    <Route
                                        exact
                                        path="/campaign/:campaignId/report"
                                        component={CampaignReport}
                                        isPrivate
                                    />
                                    <Route
                                        exact
                                        path="/campaigns-config"
                                        component={CampaignsConfig}
                                        isPrivate
                                    />
                                </>
                            )}
                            {getDialogFlowIntegration() === 1 && (
                                <>
                                    <Route
                                        exact
                                        path="/DialogFlowTemplateBuilder"
                                        component={DialogFlowTemplateBuilder}
                                        isPrivate
                                    />
                                </>
                            )}
                        </LoggedInLayout>
                    </WhatsAppsProvider>
                </Switch>
                <ToastContainer autoClose={3000} />
            </AuthProvider>
        </BrowserRouter>
    );
};

export default Routes;
