const messages = {
    pt: {
        translations: {
            signup: {
                title: "Cadastre-se",
                toasts: {
                    success: "Usuário criado com sucesso! Faça seu login!!!.",
                    fail: "Erro ao criar usuário. Verifique os dados informados.",
                },
                form: {
                    name: "Nome",
                    email: "Email",
                    password: "Senha",
                    plan: "Plano",
                    phone: "Telefone",
                    document: "CPF/CNPJ",
                },
                buttons: {
                    submit: "Cadastrar",
                    login: "Já tem uma conta? Entre!",
                },
            },
            login: {
                title: "Login",
                form: {
                    email: "Email",
                    password: "Senha",
                },
                buttons: {
                    submit: "Entrar",
                    register: "Não tem um conta? Cadastre-se!",
                },
            },
            copyright: {
                title: "Whaticket",
            },
            errors: {
                otherLogin: "Sua conta foi acessada em outro computador.",
            },
            auth: {
                toasts: {
                    success: "Login efetuado com sucesso!",
                },
            },
            messages: {
                metaChannelInfo:
                    "Você tem 24h para responder após receber uma mensagem, de acordo com as políticas do Facebook.",
            },
            dashboard: {
                filters: "Filtros",
                from: "De",
                to: "Até",
                toFilter: "Filtrar",
                cards: {
                    open: "Em Atendimento",
                    pending: "Aguardando",
                    closed: "Finalizados",
                    newContacts: "Novos Contatos",
                    totalMessages: "Total de Mensagens",
                    activeUsers: "Atendentes Ativos",
                },
                charts: {
                    perDay: {
                        title: "Tickets hoje: ",
                    },
                },
                messages: {
                    newChat: "Conversar",
                    inAttendance: {
                        title: "Em Atendimento",
                    },
                    waiting: {
                        title: "Aguardando",
                    },
                    closed: {
                        title: "Finalizado",
                    },
                },
            },
            connections: {
                title: "Conexões",
                addTitle: "Nova Conexão",
                selectConnections: "Selecione uma conexão",
                toasts: {
                    deleted: "Conexão com o WhatsApp excluída com sucesso!",
                },
                confirmationModal: {
                    deleteTitle: "Deletar",
                    deleteMessage:
                        "Você tem certeza? Essa ação não pode ser revertida.",
                    disconnectTitle: "Desconectar",
                    disconnectMessage:
                        "Tem certeza? Você precisará ler o QR Code novamente.",
                },
                buttons: {
                    add: "Nova Conexão",
                    disconnect: "desconectar",
                    tryAgain: "Tentar novamente",
                    qrcode: "QR CODE",
                    newQr: "Novo QR CODE",
                    connecting: "Conectando",
                },
                toolTips: {
                    disconnected: {
                        title: "Falha ao iniciar sessão do WhatsApp",
                        content:
                            "Certifique-se de que seu celular esteja conectado à internet e tente novamente, ou solicite um novo QR Code",
                    },
                    qrcode: {
                        title: "Esperando leitura do QR Code",
                        content:
                            "Clique no botão 'QR CODE' e leia o QR Code com o seu celular para iniciar a sessão",
                    },
                    connected: {
                        title: "Conexão estabelecida!",
                    },
                    timeout: {
                        title: "A conexão com o celular foi perdida",
                        content:
                            "Certifique-se de que seu celular esteja conectado à internet e o WhatsApp esteja aberto, ou clique no botão 'Desconectar' para obter um novo QR Code",
                    },
                    desc1: "Nome do contato",
                    desc2: "Número do contato",
                    desc3: "Nome do usuário",
                    desc4: "Número do Ticket",
                    desc5: "Saudação (Bom dia, Boa tarde, Boa noite)",
                    desc6: "Hora atual",
                    desc7: "Data atual",
                    desc8: "Departamento",
                    desc9: "Conexão (WhatsApp, Instagram, Facebook, etc)",
                    desc10: "Protocolo do Atendimento",
                    longText:
                        "Desmarque esta opção para definir um horário de expediente para os atendimentos. Quando um usuário escolher ser direcionado a um atendente, o sistema irá verificar o horário e o dia, se estiver fora do expediente, envia um aviso ao usuário e não direciona ao atendente escolhido.",
                },
                table: {
                    id: "Id",
                    channel: "Canal",
                    name: "Nome",
                    number: "Número",
                    status: "Status",
                    lastUpdate: "Última atualização",
                    default: "Padrão",
                    actions: "Ações",
                    session: "Sessão",
                },
                toast: {
                    copied: "ID da conexão copiado para a área de transferência",
                },
                qrcodeModal: {
                    title: "Para começar a usar o",
                    description1: "1. Abra o WhatsApp em seu celular.",
                    description2:
                        "2. Toque em mais opções ou configurações e selecione Aparelhos Conectados.",
                    description3:
                        "3. Aponte seu celular para essa tela para capturar o código.",
                    waiting: "Aguardando leitura do QR Code",
                },
                form: {
                    dialogFlowJson: "Arquivo JSON do DialogFlow",
                    dialogFlowLanguageCode: "Linguagem (pt-BR)",
                    webhookApiRest: "Somente API REST",
                    lgpdVCardName: "URL imagem de Saudação",
                    lgpdVCardMessage: "Mensagem de Saudação",
                    userAcceptTicket:
                        "Mensagem para quando atendente aceita a conversa",
                    transferTicketMessage:
                        "Mensagem de transferencia de atendimento",
                    reopenTicketMessage:
                        "Mensagem para quando o atendente reabre o ticket",
                    ratingMessage: "Mensagem de avaliação",
                    defineWorkHours: "Definir horário de expediente",
                },
            },
            whatsappModal: {
                title: {
                    add: "Adicionar WhatsApp",
                    edit: "Editar WhatsApp",
                },
                form: {
                    name: "Nome",
                    default: "Padrão",
                    farewellMessage: "Mensagem de despedida",
                    isMultidevice: "Multidevice",
                    transferTicketMessage:
                        "Mensagem de Transferência do Ticket",
                    reopenLastTicket: "Reabrir Ultimo Ticket",
                    minutes: "Minutos",
                    newticketafter: "Gerar um Novo Ticket após",
                    titlenewticketafter: "Ao receber uma mensagem",
                },
                buttons: {
                    okAdd: "Adicionar",
                    okEdit: "Salvar",
                    cancel: "Cancelar",
                },
                success: "WhatsApp salvo com sucesso.",
            },
            qrCode: {
                message: "Leia o QrCode para iniciar a sessão",
            },
            location: {
                preview: {
                    open: "Visualizar",
                },
            },
            contacts: {
                filters: {
                    createdAt: "Criado em",
                    from: "De",
                    to: "Até",
                },
                title: "Contatos",
                toasts: {
                    deleted: "Contato excluído com sucesso!",
                    blocked: "Contato bloqueado com sucesso!",
                    unblocked: "Contato desbloqueado com sucesso!",
                },
                importButton: "Importar / Exportar",
                importContactsButton: "Importar da Agenda",
                importExcelButton: "Importar do Excel",
                exportExcelButton: "Exportar para Excel",
                searchPlaceholder: "Pesquisar...",
                confirmationModal: {
                    deleteTitle: "Deletar ",
                    importTitlte: "Importar contatos",
                    importChatsTitle: "Importar conversas",
                    deleteMessage:
                        "Tem certeza que deseja deletar este contato? Todos os tickets relacionados serão perdidos.",
                    importMessage:
                        "Deseja importas todos os contatos do telefone?",
                    blockMessage:
                        "Tem certeza que deseja bloquear este contato?",
                    unblockMessage:
                        "Tem certeza que deseja desbloquear este contato?",
                    importChatsMessage:
                        "Deseja importar todas as conversas do telefone?",
                },
                buttons: {
                    import: "Importar Contatos",
                    add: "Adicionar Contato",
                },
                table: {
                    name: "Nome",
                    whatsapp: "WhatsApp",
                    email: "Email",
                    actions: "Ações",
                },
            },
            contactModal: {
                title: {
                    add: "Adicionar contato",
                    edit: "Editar contato",
                },
                form: {
                    mainInfo: "Dados do contato",
                    extraInfo: "Informações adicionais",
                    name: "Nome",
                    number: "Número do Whatsapp",
                    email: "Email",
                    extraName: "Nome do campo",
                    extraValue: "Valor",
                    emailPlaceholder: "Email do contato",
                },
                buttons: {
                    addExtraInfo: "Adicionar informação",
                    okAdd: "Adicionar",
                    okEdit: "Salvar",
                    cancel: "Cancelar",
                },
                success: "Contato salvo com sucesso.",
            },
            quickAnswersModal: {
                title: {
                    add: "Adicionar Resposta Rápida",
                    edit: "Editar Resposta Rápida",
                },
                form: {
                    shortcut: "Atalho",
                    message: "Resposta Rápida",
                },
                buttons: {
                    okAdd: "Adicionar",
                    okEdit: "Salvar",
                    cancel: "Cancelar",
                },
                success: "Resposta Rápida salva com sucesso.",
            },
            queueModal: {
                title: {
                    add: "Adicionar departamento",
                    edit: "Editar departamento",
                },
                form: {
                    name: "Nome",
                    color: "Cor",
                    greetingMessage: "Mensagem para escolha de departamento",
                    transferQueueMessage:
                        "Mensagem de Transferência entre Setor",
                    outOfWorkMessage:
                        "Personalize a mensagem quando estiver fora do horário de expediente",
                    startWorkHour: "Expediente: Abertura",
                    endWorkHour: "Expediente: Fechamento",
                    monday: "Segunda",
                    tuesday: "Terça",
                    wednesday: "Quarta",
                    thursday: "Quinta",
                    friday: "Sexta",
                    saturday: "Sábado",
                    sunday: "Domingo",
                    holiday: "Feriado",
                    token: "Token",
                    checkHourExpedient: "Checar Horário de Expediente",
                },
                buttons: {
                    okAdd: "Adicionar",
                    okEdit: "Salvar",
                    cancel: "Cancelar",
                },
            },
            userModal: {
                title: {
                    add: "Adicionar usuário",
                    edit: "Editar usuário",
                },
                form: {
                    name: "Nome",
                    email: "Email",
                    password: "Senha",
                    profile: "Perfil",
                    whatsapp: "Conexão Padrão",
                    color: "Cor",
                },
                buttons: {
                    okAdd: "Adicionar",
                    okEdit: "Salvar",
                    cancel: "Cancelar",
                },
                success: "Usuário salvo com sucesso.",
            },
            tagModal: {
                title: {
                    add: "Nova Tag",
                    edit: "Editar Tag",
                },
                form: {
                    name: "Nome",
                    color: "Cor",
                },
                buttons: {
                    okAdd: "Adicionar",
                    okEdit: "Salvar",
                    cancel: "Cancelar",
                },
                success: "Tag salvo com sucesso.",
            },
            chat: {
                title: "Conversa",
                noTicketMessage:
                    "Selecione um ticket para começar a conversar.",
                deleteChat: "Deletar conversa",
                deleteChatConfirmation:
                    "Esta ação não pode ser revertida, confirmar?",
                noChats: "Nenhum registro",
                cancel: "Cancelar",
                save: "Salvar",
                newChat: "Novo",
            },
            ticketsManager: {
                buttons: {
                    newTicket: "Novo",
                },
            },
            ticketsQueueSelect: {
                placeholder: "Departamentos",
            },
            tickets: {
                filters: {
                    keyWord: "Palavra-chave",
                    updatedAt: "Atualizado em",
                    to: "Até",
                },
                applyFilters: "Pesquisar",
                clearFilters: "Limpar",
                welcome: "Selecione um ticket para começar a conversar.",
                toasts: {
                    deleted: "O ticket que você estava foi deletado.",
                },
                notification: {
                    message: "Mensagem de",
                },
                tabs: {
                    open: { title: "Abertos" },
                    closed: { title: "Resolvidos" },
                    search: { title: "Busca" },
                },
                search: {
                    placeholder: "Buscar tickets e mensagens",
                },
                buttons: {
                    showAll: "Todos",
                    markAsRead: "Resolver sem despedida",
                },
                modal: {
                    deleteConfirmationTitle:
                        "Tem certeza que deseja deletar o ticket?",
                    deleteConfirmationMessage:
                        "Essa ação não poderá ser desfeita!",
                },
            },
            transferTicketModal: {
                title: "Transferir Ticket",
                fieldLabel: "Digite para buscar usuários",
                fieldQueueLabel: "Transferir para um departamento",
                fieldConnectionLabel: "Transferir para conexão",
                fieldQueuePlaceholder: "Selecione um departamento",
                fieldConnectionPlaceholder: "Selecione uma conexão",
                noOptions: "Nenhum usuário encontrado com esse nome",
                buttons: {
                    ok: "Transferir",
                    cancel: "Cancelar",
                },
            },
            ticketsList: {
                pendingHeader: "Aguardando",
                assignedHeader: "Atendendo",
                noTicketsTitle: "Nada aqui!",
                noTicketsMessage:
                    "Nenhum ticket encontrado com esse status ou termo pesquisado",
                connectionTitle: "Conexão que está sendo utilizada atualmente.",
                buttons: {
                    accept: "Aceitar",
                },
            },
            newTicketModal: {
                title: "Criar Ticket",
                fieldLabel: "Digite para pesquisar o contato",
                add: "Adicionar",
                buttons: {
                    ok: "Salvar",
                    cancel: "Cancelar",
                },
                queueLabel: "Departamento",
            },
            subscriptions: {
                title: "Assinaturas",
                table: {
                    dateCreated: "Criado Em",
                    nextDueDate: "Vence Em",
                    cycle: "Ciclo",
                    description: "Descrição",
                    billingType: "Tipo",
                    value: "Valor",
                    invoiceUrl: "Fatura",
                },
                cycle: {
                    monthly: "Mensal",
                    yearly: "Anual",
                },
                billingType: {
                    creditCard: "Cartão de Crédito",
                    boleto: "Boleto",
                    pix: "PIX",
                    undefined: "Não definido",
                },
                buttons: {
                    invoiceUrl: "Fatura",
                },
            },
            mainDrawer: {
                listItems: {
                    dashboard: "Dashboard",
                    connections: "Conexões",
                    subscriptions: "Assinaturas",
                    tickets: "Conversas",
                    contacts: "Contatos",
                    quickAnswers: "Respostas Rápidas",
                    queues: "Departamentos & Chatbots",
                    schedules: "Agendamentos",
                    tags: "Tags",
                    api: "API",
                    helps: "Ajuda",
                    gerenciarcampanha: "Campanhas",
                    configcampanha: "Config. Campanhas",
                    relatoriocampanha: "Relatório Envios",
                    enviocampanha: "Envio em Massa",
                    administration: "Administração",
                    users: "Usuários",
                    docs: "Documentação",
                    tokens: "Tokens",
                    settings: "Configurações",
                    internalChat: "Chat Interno",
                    campaignsManager: "Gerenciar Campanhas",
                    campaigns: "Campanhas",
                    contactLists: "Lista de Contatos",
                    campaignsConfig: "Config. Envios",
                    dialogFlowTemplateBuilder: "DialogFlow Template Builder",
                    apiDocs: "Documentação da API",
                    changelog: "Changelog",
                },
                appBar: {
                    user: {
                        profile: "Perfil",
                        logout: "Sair",
                    },
                },
            },
            notifications: {
                noTickets: "Nenhuma notificação.",
            },
            helps: {
                title: "Central de Ajuda",
            },
            schedules: {
                title: "Agendamentos",
                confirmationModal: {
                    deleteTitle:
                        "Você tem certeza que quer excluir este Agendamento?",
                    deleteMessage: "Esta ação não pode ser revertida.",
                },
                table: {
                    contact: "Contato",
                    body: "Mensagem",
                    sendAt: "Data de Agendamento",
                    sentAt: "Data de Envio",
                    status: "Status",
                    actions: "Ações",
                },
                buttons: {
                    add: "Novo Agendamento",
                },
                toasts: {
                    deleted: "Agendamento excluído com sucesso.",
                },
            },
            scheduleModal: {
                title: {
                    add: "Novo Agendamento",
                    edit: "Editar Agendamento",
                },
                form: {
                    body: "Mensagem",
                    contact: "Contato",
                    sendAt: "Data de Agendamento",
                    sentAt: "Data de Envio",
                },
                buttons: {
                    okAdd: "Adicionar",
                    okEdit: "Salvar",
                    cancel: "Cancelar",
                },
                success: "Agendamento salvo com sucesso.",
            },
            queues: {
                title: "Departamentos",
                table: {
                    name: "Nome",
                    color: "Cor",
                    greeting: "Mensagem de saudação",
                    actions: "Ações",
                },
                buttons: {
                    add: "Adicionar departamento",
                    addChatBot: "Adiconar opções",
                },
                confirmationModal: {
                    deleteTitle: "Excluir",
                    deleteMessage:
                        "Você tem certeza? Essa ação não pode ser revertida! Os tickets desse departamento continuarão existindo, mas não terão mais nenhum departamento atribuída.",
                    deleteDescription:
                        "Tem certeza? Todas as opções internas também serão excluídas.",
                },
                toasts: {
                    linkCopied:
                        "ID do departamento copiado para a área de transferência",
                    deleted: "Departamento excluído com sucesso.",
                    saved: "Departamento salvo com sucesso.",
                    deleteDescription:
                        "Tem certeza? Todas as opções internas também serão excluídas",
                },
                form: {
                    optionsTooltip: "Adicione opções para construir um chatbot",
                    optionsTooltipContent:
                        "Se houver apenas uma opção, ela será escolhida automaticamente, fazendo com que o bot responda com a mensagem da opção e siga adiante",
                },
                greetingMessageTooltip:
                    "A mensagem é obrigatória para seguir ao próximo nível",
                greetingMessageTooltipContent:
                    "Se a mensagem não estiver definida, o bot não seguirá adiante",
            },
            queueSelect: {
                inputLabel: "Departamentos",
            },
            quickAnswers: {
                title: "Respostas Rápidas",
                table: {
                    shortcut: "Atalho",
                    message: "Resposta Rápida",
                    actions: "Ações",
                },
                buttons: {
                    add: "Adicionar Resposta Rápida",
                },
                toasts: {
                    deleted: "Resposta Rápida excluída com sucesso.",
                },
                searchPlaceholder: "Pesquisar...",
                confirmationModal: {
                    deleteTitle:
                        "Você tem certeza que quer excluir esta Resposta Rápida: ",
                    deleteMessage: "Esta ação não pode ser revertida.",
                },
            },
            users: {
                title: "Usuários",
                table: {
                    name: "Nome",
                    email: "Email",
                    profile: "Perfil",
                    whatsapp: "Conexão Padrão",
                    actions: "Ações",
                    color: "Cor",
                },
                buttons: {
                    add: "Adicionar usuário",
                },
                toasts: {
                    deleted: "Usuário excluído com sucesso.",
                },
                confirmationModal: {
                    deleteTitle: "Excluir",
                    deleteMessage:
                        "Todos os dados do usuário serão perdidos. Os tickets abertos deste usuário serão movidos para o departamento.",
                },
            },
            tags: {
                title: "Tags",
                confirmationModal: {
                    deleteTitle: "Você tem certeza que quer excluir esta Tag?",
                    deleteMessage: "Esta ação não pode ser revertida.",
                },
                table: {
                    name: "Nome",
                    color: "Cor",
                    tickets: "Registros Tagdos",
                    actions: "Ações",
                },
                buttons: {
                    add: "Nova Tag",
                },
                toasts: {
                    deleted: "Tag excluído com sucesso.",
                },
            },
            settings: {
                success: "Configurações salvas com sucesso.",
                title: "Configurações",
                save: "Salvar",
                settings: {
                    userCreation: {
                        name: "Criação de usuário",
                        options: {
                            enabled: "Ativado",
                            disabled: "Desativado",
                        },
                    },
                    timeCreateNewTicket: {
                        name: "Mensagem de boas-vindas após",
                        note: "Selecione o tempo que será necessário para abrir um novo ticket, caso o cliente entre em contatos novamente",
                        options: {
                            10: "10 Segundos",
                            30: "30 Segundos",
                            60: "1 minuto",
                            300: "5 minutos",
                            1800: "30 minutos",
                            3600: "1 hora",
                            7200: "2 horas",
                            21600: "6 horas",
                            43200: "12 horas",
                            86400: "24 horas",
                            172800: "48 horas",
                        },
                    },
                    call: {
                        name: "Aceitar chamadas",
                        options: {
                            enabled: "Ativado",
                            disabled: "Desativado",
                        },
                    },
                    CheckMsgIsGroup: {
                        name: "Ignorar Mensagens de Grupos",
                        options: {
                            enabled: "Ativado",
                            disabled: "Desativado",
                        },
                    },
                    ticketNoQueue: {
                        name: "Visualização dos Tickets sem Fila",
                        options: {
                            all: "Todos",
                            admin: "Somente Administradores",
                        },
                    },
                    userRating: {
                        name: "Avaliação do Ticket",
                        options: {
                            enabled: "Ativado",
                            disabled: "Desativado",
                        },
                    },
                    chatBotType: {
                        name: "Tipo de ChatBot",
                        options: {
                            text: "Texto",
                            button: "Botão",
                            list: "Lista",
                        },
                    },
                    viewMsg: {
                        name: "Visualização de Mensagens pelo Sistema",
                        options: {
                            disabled: "Desativado",
                            accepted: "Somente Tickets Aceitos",
                            all: "Todos",
                        },
                    },
                    signMsg: {
                        name: "Desativar/Ativar assinatura de mensagens",
                        options: {
                            disabled: "Desativado",
                            admin: "Somente Administradores",
                            all: "Todos",
                        },
                    },
                    sendinblueApiKey: "Chave da API do Sendinblue",
                    sendinblueEmail: "Email do Sendinblue",
                    sendinblueName: "Nome do Sendinblue",
                    metabaseSiteUrl: "URL do Metabase",
                    metabaseSecretKey: "Chave secreta do Metabase",
                },
            },
            messagesList: {
                header: {
                    assignedTo: "Atribuído à:",
                    buttons: {
                        return: "Retornar",
                        resolve: "Resolver",
                        reopen: "Reabrir",
                        accept: "Aceitar",
                    },
                },
            },
            messagesInput: {
                placeholderOpen:
                    "Digite uma mensagem ou tecle ''/'' para utilizar as respostas rápidas cadastrada",
                placeholderClosed:
                    "Reabra ou aceite esse ticket para enviar uma mensagem.",
                signMessage: "Assinar",
            },
            contactDrawer: {
                header: "Dados do contato",
                buttons: {
                    edit: "Editar contato",
                },
                extraInfo: "Outras informações",
            },
            ticketOptionsMenu: {
                delete: "Deletar",
                transfer: "Transferir",
                confirmationModal: {
                    title: "Deletar o ticket do contato",
                    message:
                        "Atenção! Todas as mensagens relacionadas ao ticket serão perdidas.",
                },
                buttons: {
                    delete: "Excluir",
                    cancel: "Cancelar",
                },
            },
            confirmationModal: {
                buttons: {
                    confirm: "Ok",
                    cancel: "Cancelar",
                },
            },
            messageOptionsMenu: {
                delete: "Deletar",
                reply: "Responder",
                confirmationModal: {
                    title: "Apagar mensagem?",
                    message: "Esta ação não pode ser revertida.",
                },
            },
            contactLists: {
                title: "Listas de Contatos",
                table: {
                    name: "Nome",
                    contacts: "Contatos",
                    actions: "Ações",
                },
                buttons: {
                    add: "Nova Lista",
                    download: "Baixar Planilha Exemplo",
                },
                dialog: {
                    name: "Nome",
                    company: "Empresa",
                    okEdit: "Editar",
                    okAdd: "Adicionar",
                    add: "Adicionar",
                    edit: "Editar",
                    cancel: "Cancelar",
                },
                confirmationModal: {
                    deleteTitle: "Excluir",
                    deleteMessage: "Esta ação não pode ser revertida.",
                },
                toasts: {
                    deleted: "Registro excluído",
                },
            },
            contactListItems: {
                title: "Contatos",
                searchPlaceholder: "Pesquisa",
                buttons: {
                    add: "Novo",
                    lists: "Voltar",
                    import: "Importar",
                },
                dialog: {
                    name: "Nome",
                    number: "Número",
                    whatsapp: "Whatsapp",
                    email: "E-mail",
                    okEdit: "Editar",
                    okAdd: "Adicionar",
                    add: "Adicionar",
                    edit: "Editar",
                    cancel: "Cancelar",
                },
                table: {
                    name: "Nome",
                    number: "Número",
                    whatsapp: "Whatsapp",
                    email: "E-mail",
                    actions: "Ações",
                    whatsappValid: "Whatsapp válido",
                    whatsappInvalid: "Whatsapp inválido",
                },
                confirmationModal: {
                    deleteTitle: "Excluir",
                    deleteMessage: "Esta ação não pode ser revertida.",
                    importMessage:
                        "Deseja importar os contatos desta planilha? ",
                    importTitlte: "Importar",
                    importLink: "Clique aqui para baixar planilha exemplo.",
                },
                toasts: {
                    deleted: "Registro excluído",
                },
            },
            campaignsConfig: {
                success: "Configurações salvas com sucesso",
                messageInterval: "Intervalo Randômico de Disparo",
                longerIntervalAfter: "Intervalo Maior Após",
                greaterInterval: "Intervalo de Disparo Maior",
                noInterval: "Não Definido",
                seconds: "Segundos",
                messages: "Mensagens",
                addVariables: "Adicionar Variáveis",
                saveSettings: "Salvar Configurações",
                variableKey: "Atalho",
                variableValue: "Conteúdo",
                cancel: "Cancelar",
                add: "Adicionar",
            },
            dialogFlow: {
                title: "DialogFlow Template Builder",
                templates: {
                    media: "Mensagem com Mídia",
                    list: "Mensagem com Lista",
                    simpleButton: "Mensagem com Botões Simples",
                    templateButton: "Mensagem com Botões com Template",
                    location: "Mensagem com Localização",
                    options: "Opções",
                    addSection: "Adicionar Seção",
                    section: "Seção",
                    sectionTitle: "Título",
                    addOption: "Adicionar Opção",
                    generate: "Gerar Template",
                    localName: "Nome do Local",
                    address: "Endereço",
                },
                selectType: "Selecione um Tipo",
                selectMimeType: "Selecione um MimeType",
                image: "Imagem",
                video: "Vídeo",
                audio: "Áudio",
                doc: "Documento",
            },
            chatBots: {
                successfullySaved: "Bot salvo com sucesso",
                successfullyDeleted: "Bot excluído com sucesso",
                greetingMessage:
                    "A mensagem é obrigatória para seguir ao próximo nível",
                greetingMessageTooltip:
                    "Se a mensagem não estiver definida, o bot não seguirá adiante",
                addNewStep: "Adicionar opções",
            },
            status: {
                open: "Aberto",
                pending: "Pendente",
                closed: "Fechado",
            },
            campaigns: {
                status: {
                    inactive: "Inativa",
                    scheduled: "Agendada",
                    in_progress: "Em Andamento",
                    canceled: "Cancelada",
                    finished: "Finalizada",
                },
                report: {
                    title: "Relatório da Campanha",
                    valid_contacts: "Contatos Válidos",
                    confirmation_requested: "Confirmação Solicitada",
                    confirmed_contacts: "Contatos Confirmados",
                    delivered: "Entregues",
                    whatsapp: "Conexão",
                    contact_list: "Lista de Contatos",
                    scheduled_at: "Agendamento",
                    completed_at: "Concluída",
                    of: "de",
                },
                title: "Campanhas",
                searchPlaceholder: "Pesquisa",
                buttons: {
                    add: "Nova Campanha",
                    contactLists: "Listas de Contatos",
                    cancel: "Parar Campanha",
                },
                table: {
                    name: "Nome",
                    whatsapp: "Conexão",
                    contactList: "Lista de Contatos",
                    status: "Status",
                    scheduledAt: "Agendamento",
                    completedAt: "Concluída",
                    confirmation: "Confirmação",
                    actions: "Ações",
                    noDefined: "Não Definida",
                    noScheduled: "Sem agendamento",
                    noCompleted: "Não concluída",
                },
                dialog: {
                    new: "Nova Campanha",
                    update: "Editar Campanha",
                    readonly: "Apenas Visualização",
                    form: {
                        name: "Nome",
                        message1: "Mensagem 1",
                        message2: "Mensagem 2",
                        message3: "Mensagem 3",
                        message4: "Mensagem 4",
                        message5: "Mensagem 5",
                        confirmationMessage1: "Mensagem de Confirmação 1",
                        confirmationMessage2: "Mensagem de Confirmação 2",
                        confirmationMessage3: "Mensagem de Confirmação 3",
                        confirmationMessage4: "Mensagem de Confirmação 4",
                        confirmationMessage5: "Mensagem de Confirmação 5",
                        emailSubject1: "Assunto do Email 1",
                        emailSubject2: "Assunto do Email 2",
                        emailSubject3: "Assunto do Email 3",
                        emailSubject4: "Assunto do Email 4",
                        emailSubject5: "Assunto do Email 5",
                        messagePlaceholder: "Conteúdo da mensagem",
                        whatsapp: "Conexão",
                        status: "Status",
                        scheduledAt: "Agendamento",
                        confirmation: "Confirmação",
                        contactList: "Lista de Contato",
                        messageHelperText:
                            "Utilize variáveis como {name}, {number}, {email} ou defina variáveis personalizadas.",
                        sendEmailFalse: "Não",
                        sendEmailTrue: "Sim",
                        contactListNone: "Nenhuma",
                        whatsappNone: "Nenhuma",
                        emailMessage: "Escreva aqui o corpo do e-mail",
                        sendEmail: "Enviar e-mail",
                    },
                    buttons: {
                        add: "Adicionar",
                        edit: "Atualizar",
                        okadd: "Ok",
                        cancel: "Cancelar Disparos",
                        restart: "Reiniciar Disparos",
                        close: "Fechar",
                        attach: "Anexar Arquivo",
                    },
                },
                confirmationModal: {
                    deleteTitle: "Excluir",
                    deleteMessage: "Esta ação não pode ser revertida.",
                },
                toasts: {
                    success: "Operação realizada com sucesso",
                    cancel: "Campanha cancelada",
                    restart: "Campanha reiniciada",
                    deleted: "Registro excluído",
                },
            },
            backendErrors: {
                ERR_TAG_NAME_ALREADY_EXISTS: "Já existe uma tag com esse nome.",
                ERR_NO_OTHER_WHATSAPP:
                    "Deve haver pelo menos um WhatsApp padrão.",
                ERR_NO_DEF_WAPP_FOUND:
                    "Nenhum WhatsApp padrão encontrado. Verifique a página de conexões.",
                ERR_WAPP_NOT_INITIALIZED:
                    "Esta sessão do WhatsApp não foi inicializada. Verifique a página de conexões.",
                ERR_WAPP_CHECK_CONTACT:
                    "Não foi possível verificar o contato do WhatsApp. Verifique a página de conexões",
                ERR_WAPP_INVALID_CONTACT:
                    "Este não é um número de Whatsapp válido.",
                ERR_WAPP_DOWNLOAD_MEDIA:
                    "Não foi possível baixar mídia do WhatsApp. Verifique a página de conexões.",
                ERR_INVALID_CREDENTIALS:
                    "Erro de autenticação. Por favor, tente novamente.",
                ERR_SENDING_WAPP_MSG:
                    "Erro ao enviar mensagem do WhatsApp. Verifique a página de conexões.",
                ERR_DELETE_WAPP_MSG:
                    "Não foi possível excluir a mensagem do WhatsApp.",
                ERR_OTHER_OPEN_TICKET:
                    "Já existe um tíquete aberto para este contato.",
                ERR_LICENSE_INVALID:
                    "Sessão inválida. Entre em contato com o suporte.",
                ERR_SESSION_EXPIRED: "Sessão expirada. Por favor entre.",
                ERR_USER_CREATION_DISABLED:
                    "A criação do usuário foi desabilitada pelo administrador.",
                ERR_NO_PERMISSION:
                    "Você não tem permissão para acessar este recurso.",
                ERR_DUPLICATED_CONTACT: "Já existe um contato com este número.",
                ERR_NO_SETTING_FOUND:
                    "Nenhuma configuração encontrada com este ID.",
                ERR_NO_CONTACT_FOUND: "Nenhum contato encontrado com este ID.",
                ERR_NO_TICKET_FOUND: "Nenhum tíquete encontrado com este ID.",
                ERR_NO_USER_FOUND: "Nenhum usuário encontrado com este ID.",
                ERR_NO_WAPP_FOUND: "Nenhum WhatsApp encontrado com este ID.",
                ERR_CREATING_MESSAGE:
                    "Erro ao criar mensagem no banco de dados.",
                ERR_CREATING_TICKET: "Erro ao criar tíquete no banco de dados.",
                ERR_FETCH_WAPP_MSG:
                    "Erro ao buscar a mensagem no WhatsApp, talvez ela seja muito antiga.",
                ERR_QUEUE_COLOR_ALREADY_EXISTS:
                    "Esta cor já está em uso, escolha outra.",
                ERR_WAPP_GREETING_REQUIRED:
                    "A mensagem de saudação é obrigatório quando há mais de um departamento.",
                ERR_FETCHING_FB_USER_TOKEN:
                    "Não foi possível buscar as informações do usuário no Facebook.",
                ERR_FETCHING_FB_PAGES:
                    "Não foi possível buscar as informações das páginas no Facebook.",
                ERR_FETCHING_INSTAGRAM_LINKED_PAGE:
                    "Não foi possível buscar as informações da pagina do Instagram. Certifique-se de que a página está associada é uma página do Facebook.",
                ERR_SUBSCRIBING_PAGE_TO_MESSAGE_WEBHOOKS:
                    "Não foi possível se inscrever para receber as mensagens do Facebook, contate o suporte.",
                ERR_COMPANY_INACTIVE:
                    "Sua empresa está inativa. Entre em contato com o suporte.",
                ERR_LICENSE_EXPIRED:
                    "Sua licença expirou. Entre em contato com o suporte.",
                ERR_COMPANY_PHONE_ALREADY_EXISTS:
                    "Já existe uma empresa com este número de telefone.",
                ERR_COMPANY_PHONE_INVALID:
                    "Este não é um número de whatsapp válido.",
                ERR_COMPANY_DOCUMENT_ALREADY_EXISTS:
                    "Já existe uma empresa com este CPF/CNPJ.",
                ERR_COMPANY_EMAIL_ALREADY_EXISTS:
                    "Já existe uma empresa com este e-mail.",
            },
        },
    },
};

export { messages };
