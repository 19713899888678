import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import { socketConnection } from "../../services/socket";
import toastError from "../../errors/toastError";
import { makeStyles } from "@material-ui/core/styles";

import { Dialog, DialogContent, Paper, Typography } from "@material-ui/core";
import api from "../../services/api";
import { getAppName } from "../../config";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
    QrcodeModal: {
        display: "flex",
        jusitfyContent: "center",
        padding: "50px",
    },
    QrcodeModalDiv: {
        padding: "20px",
    },
}));

const QrcodeModal = ({ open, onClose, whatsAppId }) => {
    const [qrCode, setQrCode] = useState("");
    const classes = useStyles();

    useEffect(() => {
        const fetchSession = async () => {
            if (!whatsAppId) return;

            try {
                const { data } = await api.get(`/whatsapp/${whatsAppId}`);
                setQrCode(data.qrcode);
            } catch (err) {
                toastError(err);
            }
        };
        fetchSession();
    }, [whatsAppId]);

    useEffect(() => {
        if (!whatsAppId) return;
        const companyId = localStorage.getItem("companyId");
        const socket = socketConnection({ companyId });

        socket.on(`company-${companyId}-whatsappSession`, (data) => {
            if (data.action === "update" && data.session.id === whatsAppId) {
                setQrCode(data.session.qrcode);
            }

            if (data.action === "update" && data.session.qrcode === "") {
                onClose();
            }
        });

        return () => {
            socket.disconnect();
        };
    }, [whatsAppId, onClose]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" scroll="paper">
            <DialogContent className={classes.QrcodeModal}>
                <div className={classes.QrcodeModalDiv}>
                    <h1>
                        {i18n.t("connections.qrcodeModal.title")} {getAppName()}
                    </h1>
                    <p>{i18n.t("connections.qrcodeModal.description1")}</p>
                    <p>{i18n.t("connections.qrcodeModal.description2")}</p>
                    <p>{i18n.t("connections.qrcodeModal.description3")}</p>
                </div>
                <div className={classes.QrcodeModalDiv}>
                    <Paper elevation={0}>
                        <Typography color="primary" gutterBottom></Typography>
                        {qrCode ? (
                            <QRCode value={qrCode} size={256} />
                        ) : (
                            <span>
                                {i18n.t("connections.qrcodeModal.waiting")}
                            </span>
                        )}
                    </Paper>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default React.memo(QrcodeModal);
