import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import { DeleteOutline } from "@material-ui/icons";
import {
    Button,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@material-ui/core";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    multFieldLine: {
        display: "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        },
    },
    optionContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: theme.spacing(1),
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        },
    },
}));

const DialogFlowTemplateBuilder = () => {
    const classes = useStyles();

    const [urlFile, setUrlFile] = useState("");
    const [type, setType] = useState("");
    const [mimeType, setMimeType] = useState("");
    const [fileName, setFileName] = useState("");
    const [message, setMessage] = useState("");

    const [mediaTemplates, setMediaTemplates] = useState("");
    const [listTemplates, setListTemplates] = useState("");
    const [simpleButtonTemplates, setSimpleButtonTemplates] = useState("");
    const [templateButtonTemplates, setTemplateButtonTemplates] = useState("");
    const [locationTemplates, setLocationTemplates] = useState("");

    const [buttonText, setButtonText] = useState("");
    const [text, setText] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [sections, setSections] = useState([]);

    const [textButton, setTextButton] = useState("");
    const [footerButton, setFooterButton] = useState("");
    const [buttons, setButtons] = useState([]);

    const [textTButton, setTextTButton] = useState("");
    const [footerTButton, setFooterTButton] = useState("");
    const [TButtons, setTButtons] = useState([]);

    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [nameLocation, setNameLocation] = useState("");
    const [addressLocation, setAddressLocation] = useState("");

    useEffect(() => {
        setSections([
            {
                title: "",
                options: [
                    {
                        title: "",
                        description: "",
                        rowId: "",
                    },
                ],
            },
        ]);

        setButtons([
            {
                buttonId: "",
                displayText: "",
            },
        ]);

        setTButtons([
            {
                index: "",
                type: "",
                title: "",
                id: "",
                payload: "",
            },
        ]);
    }, []);

    const handleAddSection = () => {
        setSections((sections) => [
            ...sections,
            {
                title: "",
                options: [
                    {
                        title: "",
                        description: "",
                        rowId: "",
                    },
                ],
            },
        ]);
    };

    const handleRemoveSection = (index) => {
        setSections((sections) => sections.filter((_, i) => i !== index));
    };

    const handleAddOption = (sectionIndex) => {
        setSections((sections) =>
            sections.map((section, i) => {
                if (i === sectionIndex) {
                    return {
                        ...section,
                        options: [
                            ...section.options,
                            {
                                title: "",
                                description: "",
                                rowId: "",
                            },
                        ],
                    };
                }
                return section;
            })
        );
    };

    const handleRemoveOption = (sectionIndex, optionIndex) => {
        setSections((sections) =>
            sections.map((section, i) => {
                if (i === sectionIndex) {
                    return {
                        ...section,
                        options: section.options.filter(
                            (_, j) => j !== optionIndex
                        ),
                    };
                }
                return section;
            })
        );
    };

    const handleSectionTitleChange = (index, value) => {
        setSections((sections) =>
            sections.map((section, i) => {
                if (i === index) {
                    return {
                        ...section,
                        title: value,
                    };
                }
                return section;
            })
        );
    };

    const handleOptionChange = (sectionIndex, optionIndex, field, value) => {
        setSections((sections) =>
            sections.map((section, i) => {
                if (i === sectionIndex) {
                    return {
                        ...section,
                        options: section.options.map((option, j) => {
                            if (j === optionIndex) {
                                return {
                                    ...option,
                                    [field]: value,
                                };
                            }
                            return option;
                        }),
                    };
                }
                return section;
            })
        );
    };

    const handleAddButton = () => {
        setButtons((buttons) => [
            ...buttons,
            {
                buttonId: "",
                displayText: "",
            },
        ]);
    };

    const handleRemoveButton = (index) => {
        setButtons((buttons) => buttons.filter((_, i) => i !== index));
    };

    const handleButtonChange = (index, field, value) => {
        setButtons((buttons) =>
            buttons.map((button, i) => {
                if (i === index) {
                    return {
                        ...button,
                        [field]: value,
                    };
                }
                return button;
            })
        );
    };

    const handleAddTButton = () => {
        setTButtons((TButtons) => [
            ...TButtons,
            {
                index: "",
                type: "",
                title: "",
                id: "",
                payload: "",
            },
        ]);
    };

    const handleRemoveTButton = (index) => {
        setTButtons((TButtons) => TButtons.filter((_, i) => i !== index));
    };

    const handleTButtonChange = (index, field, value) => {
        setTButtons((TButtons) =>
            TButtons.map((TButton, i) => {
                if (i === index) {
                    return {
                        ...TButton,
                        [field]: value,
                    };
                }
                return TButton;
            })
        );
    };

    const handleTemplateMedia = async () => {
        let templateString = "MSG_MEDIA_URL|";

        templateString += `${urlFile}|`;
        templateString += `${type}|`;
        templateString += `${mimeType}|`;
        templateString += `${fileName}|`;
        templateString += `${message}`;

        setMediaTemplates(templateString);
    };

    const handleTemplateList = async () => {
        let templateString = "MSG_LIST|";

        templateString += `${textButton}|`;
        templateString += `${text}|`;
        templateString += `${title}|`;
        templateString += `${description}|`;

        sections.forEach((section, index) => {
            templateString += `${section.title}:`;

            section.options.forEach((option, index) => {
                templateString += `${option.title}=`;
                templateString += `${option.description}=`;
                templateString += `${option.rowId}-`;
            });

            templateString += ",";
        });

        templateString += ":0";

        setListTemplates(
            templateString.split("-,").join(",").split(",:0").join(":0")
        );
    };

    const handleTemplateSimpleButton = async () => {
        let templateString = "MSG_SIMPLE_BUTTON|";

        templateString += `${buttonText}|`;

        buttons.forEach((button, index) => {
            templateString += `${button.buttonId},`;
            templateString += `${button.displayText}:`;
        });

        templateString += "|";

        templateString += `${footerButton}`;

        setSimpleButtonTemplates(templateString.split(":|").join("|"));
    };

    const handleTemplateTButtonList = async () => {
        let templateString = "MSG_TEMPLATE_BUTTON|";

        templateString += `${textTButton}|`;

        TButtons.forEach((TButton, index) => {
            templateString += `${TButton.index},`;
            templateString += `${TButton.type},`;
            templateString += `${TButton.title},`;
            templateString += `${TButton.id},`;
            templateString += `${TButton.payload}:`;
        });

        templateString += "|";

        templateString += `${footerTButton}`;

        setTemplateButtonTemplates(templateString.split(":|").join("|"));
    };

    const handleTemplateLocation = async () => {
        let templateString = "MSG_LOCATION|";

        templateString += `${latitude}|`;
        templateString += `${longitude}|`;
        templateString += `${nameLocation}|`;
        templateString += `${addressLocation}`;

        setLocationTemplates(templateString);
    };

    return (
        <MainContainer>
            <MainHeader>
                <Title>{i18n.t("dialogFlow.title")}</Title>
            </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined">
                <Typography variant="h4" component="h2">
                    {i18n.t("dialogFlow.templates.media")}
                </Typography>
                <TextField
                    minRows={1}
                    multiline={true}
                    label="URL da mídia"
                    name="urlFile"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={urlFile}
                    onChange={(e) => setUrlFile(e.target.value)}
                />
                <FormControl fullWidth margin="dense" variant="outlined">
                    <InputLabel>{i18n.t("dialogFlow.selectType")}</InputLabel>
                    <Select
                        name="type"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                    >
                        <MenuItem key="image" value="image">
                            {i18n.t("dialogFlow.image")}
                        </MenuItem>
                        <MenuItem key="video" value="video">
                            {i18n.t("dialogFlow.video")}
                        </MenuItem>
                        <MenuItem key="audio" value="audio">
                            {i18n.t("dialogFlow.audio")}
                        </MenuItem>
                        <MenuItem key="doc" value="doc">
                            {i18n.t("dialogFlow.doc")}
                        </MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="dense" variant="outlined">
                    <InputLabel>
                        {i18n.t("dialogFlow.selectMimeType")}
                    </InputLabel>
                    <Select
                        name="mimeType"
                        value={mimeType}
                        onChange={(e) => setMimeType(e.target.value)}
                    >
                        {type === "image" && (
                            <MenuItem key="image/jpeg" value="image/jpeg">
                                JPG - image/jpeg
                            </MenuItem>
                        )}
                        {type === "image" && (
                            <MenuItem key="image/png" value="image/png">
                                PNG - image/png
                            </MenuItem>
                        )}
                        {type === "video" && (
                            <MenuItem key="video/mp4" value="video/mp4">
                                MP4 - video/mp4
                            </MenuItem>
                        )}
                        {type === "audio" && (
                            <MenuItem key="audio/ogg" value="audio/ogg">
                                OGG/MP3 - audio/ogg
                            </MenuItem>
                        )}
                        {type === "doc" && (
                            <MenuItem
                                key="application/pdf"
                                value="application/pdf"
                            >
                                PDF - application/pdf
                            </MenuItem>
                        )}
                        {type === "doc" && (
                            <MenuItem
                                key="application/vnd.ms-excel"
                                value="application/vnd.ms-excel"
                            >
                                XLS - application/vnd.ms-excel
                            </MenuItem>
                        )}
                        {type === "doc" && (
                            <MenuItem
                                key="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                value="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            >
                                XLSX -
                                application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
                            </MenuItem>
                        )}
                        {type === "doc" && (
                            <MenuItem
                                key="application/zip"
                                value="application/zip"
                            >
                                ZIP - application/zip
                            </MenuItem>
                        )}
                        {type === "doc" && (
                            <MenuItem
                                key="application/vnd.rar"
                                value="application/vnd.rar"
                            >
                                RAR - application/vnd.rar
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>
                <TextField
                    minRows={1}
                    multiline={true}
                    label="Nome do Arquivo"
                    name="fileName"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={fileName}
                    onChange={(e) => setFileName(e.target.value)}
                />
                <TextField
                    minRows={5}
                    multiline={true}
                    label="Mensagem"
                    name="message"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
                <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={() => handleTemplateMedia()}
                >
                    {i18n.t("dialogFlow.templates.generate")}
                </Button>
                <TextField
                    minRows={5}
                    multiline={true}
                    label="Template"
                    name="template"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={mediaTemplates}
                />
                <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />
                <Typography variant="h4" component="h2">
                    {i18n.t("dialogFlow.templates.list")}
                </Typography>
                <TextField
                    minRows={1}
                    multiline={true}
                    label="Texto do Botão"
                    name="buttonText"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    onChange={(e) => setTextButton(e.target.value)}
                />
                <TextField
                    minRows={5}
                    multiline={true}
                    label="Texto"
                    name="text"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    onChange={(e) => setText(e.target.value)}
                />
                <TextField
                    minRows={1}
                    multiline={true}
                    label="Título"
                    name="title"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    onChange={(e) => setTitle(e.target.value)}
                />
                <TextField
                    minRows={1}
                    multiline={true}
                    label="Descrição de Rodapé"
                    name="description"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    onChange={(e) => setDescription(e.target.value)}
                />
                <Typography variant="h6" component="h2">
                    {i18n.t("dialogFlow.templates.options")}
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={() => handleAddSection()}
                >
                    {i18n.t("dialogFlow.templates.addSection")}
                </Button>

                {sections.map((section, index) => (
                    <div key={index}>
                        <Typography variant="h6" component="h2">
                            {i18n.t("dialogFlow.templates.section")} {index + 1}
                        </Typography>
                        <TextField
                            minRows={1}
                            multiline={true}
                            label={i18n.t("dialogFlow.templates.sectionTitle")}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            onChange={(e) =>
                                handleSectionTitleChange(index, e.target.value)
                            }
                        />

                        <Button
                            variant="contained"
                            color="primary"
                            type="button"
                            onClick={() => handleAddOption(index)}
                        >
                            {i18n.t("dialogFlow.templates.addOption")}
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            type="button"
                            onClick={() => handleRemoveSection(index)}
                        >
                            <DeleteOutline />
                        </Button>

                        {section.options.map((option, optionIndex) => (
                            <div
                                key={optionIndex}
                                className={classes.optionContainer}
                            >
                                <TextField
                                    minRows={1}
                                    multiline={true}
                                    label="Título"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    onChange={(e) =>
                                        handleOptionChange(
                                            index,
                                            optionIndex,
                                            "title",
                                            e.target.value
                                        )
                                    }
                                />
                                <TextField
                                    minRows={1}
                                    multiline={true}
                                    label="Descrição"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    onChange={(e) =>
                                        handleOptionChange(
                                            index,
                                            optionIndex,
                                            "description",
                                            e.target.value
                                        )
                                    }
                                />
                                <TextField
                                    minRows={1}
                                    multiline={true}
                                    label="Id"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    onChange={(e) =>
                                        handleOptionChange(
                                            index,
                                            optionIndex,
                                            "rowId",
                                            e.target.value
                                        )
                                    }
                                />
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    type="button"
                                    onClick={() =>
                                        handleRemoveOption(index, optionIndex)
                                    }
                                >
                                    <DeleteOutline />
                                </Button>
                            </div>
                        ))}
                    </div>
                ))}

                <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={() => handleTemplateList()}
                >
                    {i18n.t("dialogFlow.templates.generate")}
                </Button>
                <TextField
                    minRows={5}
                    multiline={true}
                    label="Template"
                    name="template"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={listTemplates}
                />
                <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />
                <Typography variant="h4" component="h2">
                    {i18n.t("dialogFlow.templates.simpleButton")}
                </Typography>
                <TextField
                    minRows={1}
                    multiline={true}
                    label="Texto"
                    name="buttonText"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    onChange={(e) => setButtonText(e.target.value)}
                />
                <TextField
                    minRows={1}
                    multiline={true}
                    label="Rodapé"
                    name="footerButton"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    onChange={(e) => setFooterButton(e.target.value)}
                />
                <Typography variant="h6" component="h2">
                    {i18n.t("dialogFlow.templates.options")}
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={() => handleAddButton()}
                >
                    {i18n.t("dialogFlow.templates.addOption")}
                </Button>
                {buttons.map((option, index) => (
                    <div key={index} className={classes.optionContainer}>
                        <TextField
                            minRows={1}
                            multiline={true}
                            label="Id"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            onChange={(e) =>
                                handleButtonChange(
                                    index,
                                    "buttonId",
                                    e.target.value
                                )
                            }
                        />
                        <TextField
                            minRows={1}
                            multiline={true}
                            label="Texto do botão"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            onChange={(e) =>
                                handleButtonChange(
                                    index,
                                    "displayText",
                                    e.target.value
                                )
                            }
                        />
                        <Button
                            variant="contained"
                            color="secondary"
                            type="button"
                            onClick={() => handleRemoveButton(index)}
                        >
                            <DeleteOutline />
                        </Button>
                    </div>
                ))}
                <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={() => handleTemplateSimpleButton()}
                >
                    {i18n.t("dialogFlow.templates.generate")}
                </Button>
                <TextField
                    minRows={5}
                    multiline={true}
                    label="Template"
                    name="template"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={simpleButtonTemplates}
                />
                <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />
                <Typography variant="h4" component="h2">
                    {i18n.t("dialogFlow.templates.templateButton")}
                </Typography>
                <TextField
                    minRows={1}
                    multiline={true}
                    label="Texto"
                    name="TButtonText"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    onChange={(e) => setTextTButton(e.target.value)}
                />
                <TextField
                    minRows={1}
                    multiline={true}
                    label="Rodapé"
                    name="TFooterButton"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    onChange={(e) => setFooterTButton(e.target.value)}
                />
                <Typography variant="h6" component="h2">
                    {i18n.t("dialogFlow.templates.options")}
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={() => handleAddTButton()}
                >
                    {i18n.t("dialogFlow.templates.addOption")}
                </Button>
                {TButtons.map((option, index) => (
                    <div key={index} className={classes.optionContainer}>
                        <TextField
                            minRows={1}
                            multiline={true}
                            label="Index"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            onChange={(e) =>
                                handleTButtonChange(
                                    index,
                                    "index",
                                    e.target.value
                                )
                            }
                        />
                        <TextField
                            minRows={1}
                            multiline={true}
                            label="Tipo"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            onChange={(e) =>
                                handleTButtonChange(
                                    index,
                                    "type",
                                    e.target.value
                                )
                            }
                        />
                        <TextField
                            minRows={1}
                            multiline={true}
                            label="Título"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            onChange={(e) =>
                                handleTButtonChange(
                                    index,
                                    "title",
                                    e.target.value
                                )
                            }
                        />
                        <TextField
                            minRows={1}
                            multiline={true}
                            label="Id"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            onChange={(e) =>
                                handleTButtonChange(index, "id", e.target.value)
                            }
                        />
                        <TextField
                            minRows={1}
                            multiline={true}
                            label="Url"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            onChange={(e) =>
                                handleTButtonChange(
                                    index,
                                    "payload",
                                    e.target.value
                                )
                            }
                        />
                        <Button
                            variant="contained"
                            color="secondary"
                            type="button"
                            onClick={() => handleRemoveTButton(index)}
                        >
                            <DeleteOutline />
                        </Button>
                    </div>
                ))}
                <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={() => handleTemplateTButtonList()}
                >
                    {i18n.t("dialogFlow.templates.generate")}
                </Button>
                <TextField
                    minRows={5}
                    multiline={true}
                    label="Template"
                    name="template"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={templateButtonTemplates}
                />
                <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />
                <Typography variant="h4" component="h2">
                    {i18n.t("dialogFlow.templates.location")}
                </Typography>
                <TextField
                    minRows={1}
                    multiline={true}
                    label="Latitude"
                    name="latitude"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    onChange={(e) => setLatitude(e.target.value)}
                />
                <TextField
                    minRows={1}
                    multiline={true}
                    label="Longitude"
                    name="longitude"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    onChange={(e) => setLongitude(e.target.value)}
                />
                <TextField
                    minRows={1}
                    multiline={true}
                    label={i18n.t("dialogFlow.templates.localName")}
                    name="nameLocation"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    onChange={(e) => setNameLocation(e.target.value)}
                />
                <TextField
                    minRows={1}
                    multiline={true}
                    label={i18n.t("dialogFlow.templates.address")}
                    name="addressLocation"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    onChange={(e) => setAddressLocation(e.target.value)}
                />
                <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={() => handleTemplateLocation()}
                >
                    {i18n.t("dialogFlow.templates.generate")}
                </Button>
                <TextField
                    minRows={5}
                    multiline={true}
                    label="Template"
                    name="template"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={locationTemplates}
                />
            </Paper>
        </MainContainer>
    );
};

export default DialogFlowTemplateBuilder;
