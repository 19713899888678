function getConfig(name, defaultValue = null) {
    // If inside a docker container, use window.ENV
    if (window.ENV !== undefined) {
        return window.ENV[name] || defaultValue;
    }

    return process.env[name] || defaultValue;
}

function getPlan(name) {
    const plan = JSON.parse(localStorage.getItem("plan"));

    if (plan) {
        return plan[name];
    }

    return 0;
}

export function getBackendUrl() {
    return getConfig("REACT_APP_BACKEND_URL");
}

export function getHoursCloseTicketsAuto() {
    return getConfig("REACT_APP_HOURS_CLOSE_TICKETS_AUTO");
}

export function getPrimaryColor() {
    return getConfig("REACT_APP_PRIMARY_COLOR");
}

export function getPrimaryDark() {
    return getConfig("REACT_APP_PRIMARY_DARK");
}

export function getFavicon() {
    return getConfig("REACT_APP_FAVICON");
}

export function getLogoDash() {
    return getConfig("REACT_APP_LOGO_DASH");
}

export function getLogoWhite() {
    return getConfig("REACT_APP_LOGO_WHITE");
}

export function getLogo() {
    return getConfig("REACT_APP_LOGO");
}

export function getAppName() {
    return getConfig("REACT_APP_NAME");
}

export function getCompany() {
    return getConfig("REACT_APP_COMPANY");
}

export function getMetabaseIntegration() {
    return getPlan("metabase");
}

export function getInternalChat() {
    return getPlan("internalChat");
}

export function getDialogFlowIntegration() {
    return getPlan("dialogFlow");
}

export function getWebhookIntegration() {
    return getPlan("webhook");
}

export function getTicketRate() {
    return getPlan("ticketRate");
}

export function getCampaign() {
    return getPlan("campaign");
}

export function getApiRest() {
    return getPlan("apiRest");
}

export function getMsgLgpd() {
    return getPlan("lgpd");
}

export function getFacebookIntegration() {
    return getPlan("meta");
}

export function facebookAppId() {
    return getConfig("REACT_APP_FACEBOOK_APP_ID");
}

export function docUrl() {
    return getConfig("REACT_APP_DOC_URL");
}
