import { useState, useEffect } from "react";
import toastError from "../../errors/toastError";

import api from "../../services/api";

const useMessages = ({ searchParam, pageNumber, date, dateStart, dateEnd }) => {
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);
    const [messages, setMessages] = useState([]);
    const [count, setCount] = useState(0);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchMessages = async () => {
                try {
                    const { data } = await api.get("/messages-all", {
                        params: {
                            searchParam,
                            pageNumber,
                            date,
                            dateStart,
                            dateEnd,
                        },
                    });
                    setMessages(data.messages);

                    setHasMore(data.hasMore);
                    setCount(data.count);
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    toastError(err);
                }
            };

            fetchMessages();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber, date, dateStart, dateEnd]);

    return { messages, loading, hasMore, count };
};

export default useMessages;
