import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { toast } from "react-toastify";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import api from "../../services/api";
import toastError from "../../errors/toastError";

import { i18n } from "../../translate/i18n";
import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Tab,
    Tabs,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    textRight: {
        textAlign: "right",
    },
    tabPanelsContainer: {
        padding: theme.spacing(2),
    },
}));

const initialSettings = {
    CheckMsgIsGroup: "enabled",
    ticketNoQueue: "all",
    userRating: "disabled",
    call: "enabled",
    chatBotType: "text",
    viewMsg: "all",
    signMsg: "all",
    sendinblueApiKey: "",
    sendinblueEmail: "",
    sendinblueName: "",
    metabaseSiteUrl: "",
    metabaseSecretKey: "",
};

const Settings = () => {
    const classes = useStyles();

    const [settings, setSettings] = useState(initialSettings);
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    };

    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box p={3}>{children}</Box>}
            </div>
        );
    };

    useEffect(() => {
        api.get("/settings").then(({ data }) => {
            const settingsList = [];
            if (Array.isArray(data) && data.length > 0) {
                data.forEach((item) => {
                    settingsList.push([item.key, item.value]);
                });
                setSettings(Object.fromEntries(settingsList));
            }
        });
    }, []);

    const handleOnChangeSettings = (event) => {
        const { name, value } = event.target;
        setSettings({ ...settings, [name]: value });
    };

    const handleSaveSettings = () => {
        const settingsList = Object.entries(settings);
        settingsList.forEach(async (item) => {
            const selectedValue = item[1];
            const settingKey = item[0];

            try {
                await api.put(`/settings/${settingKey}`, {
                    value: selectedValue,
                });
            } catch (err) {
                toastError(err);
            }
        });
        toast.success("Configurações salvas com sucesso!");
    };

    return (
        <MainContainer>
            <MainHeader>
                <Grid style={{ width: "99.6%" }} container>
                    <Grid xs={12} item>
                        <Title>{i18n.t("settings.title")}</Title>
                    </Grid>
                </Grid>
            </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined">
                <Box className={classes.tabPanelsContainer}>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        aria-label="simple tabs example"
                    >
                        <Tab label="Tickets" {...a11yProps(0)} />
                        <Tab label="Mensagens" {...a11yProps(1)} />
                        <Tab label="Email" {...a11yProps(2)} />
                        <Tab label="Dashboard" {...a11yProps(3)} />
                    </Tabs>
                    <TabPanel value={tabValue} index={0}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                >
                                    <InputLabel id="CheckMsgIsGroup-label">
                                        {i18n.t(
                                            "settings.settings.CheckMsgIsGroup.name"
                                        )}
                                    </InputLabel>
                                    <Select
                                        labelId="CheckMsgIsGroup-label"
                                        id="CheckMsgIsGroup"
                                        value={settings.CheckMsgIsGroup}
                                        onChange={handleOnChangeSettings}
                                        label="CheckMsgIsGroup"
                                        name="CheckMsgIsGroup"
                                    >
                                        <MenuItem value={"enabled"}>
                                            {i18n.t(
                                                "settings.settings.CheckMsgIsGroup.options.enabled"
                                            )}
                                        </MenuItem>
                                        <MenuItem value={"disabled"}>
                                            {i18n.t(
                                                "settings.settings.CheckMsgIsGroup.options.disabled"
                                            )}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                >
                                    <InputLabel id="ticketNoQueue-label">
                                        {i18n.t(
                                            "settings.settings.ticketNoQueue.name"
                                        )}
                                    </InputLabel>
                                    <Select
                                        labelId="ticketNoQueue-label"
                                        id="ticketNoQueue"
                                        value={settings.ticketNoQueue}
                                        onChange={handleOnChangeSettings}
                                        label="Ticket No Queue"
                                        name="ticketNoQueue"
                                    >
                                        <MenuItem value={"all"}>
                                            {i18n.t(
                                                "settings.settings.ticketNoQueue.options.all"
                                            )}
                                        </MenuItem>
                                        <MenuItem value={"admin"}>
                                            {i18n.t(
                                                "settings.settings.ticketNoQueue.options.admin"
                                            )}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                >
                                    <InputLabel id="userRating-label">
                                        {i18n.t(
                                            "settings.settings.userRating.name"
                                        )}
                                    </InputLabel>
                                    <Select
                                        labelId="userRating-label"
                                        id="userRating"
                                        value={settings.userRating}
                                        onChange={handleOnChangeSettings}
                                        label="User Rating"
                                        name="userRating"
                                    >
                                        <MenuItem value={"disabled"}>
                                            {i18n.t(
                                                "settings.settings.userRating.options.disabled"
                                            )}
                                        </MenuItem>
                                        <MenuItem value={"enabled"}>
                                            {i18n.t(
                                                "settings.settings.userRating.options.enabled"
                                            )}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSaveSettings}
                            style={{ marginTop: 10 }}
                        >
                            {i18n.t("settings.save")}
                        </Button>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                >
                                    <InputLabel id="call-label">
                                        {i18n.t("settings.settings.call.name")}
                                    </InputLabel>
                                    <Select
                                        labelId="call-label"
                                        id="call"
                                        value={settings.call}
                                        onChange={handleOnChangeSettings}
                                        label="Call"
                                        name="call"
                                    >
                                        <MenuItem value={"enabled"}>
                                            {i18n.t(
                                                "settings.settings.call.options.enabled"
                                            )}
                                        </MenuItem>
                                        <MenuItem value={"disabled"}>
                                            {i18n.t(
                                                "settings.settings.call.options.disabled"
                                            )}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {/* <Grid item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                >
                                    <InputLabel id="chatBotType-label">
                                        {i18n.t(
                                            "settings.settings.chatBotType.name"
                                        )}
                                    </InputLabel>
                                    <Select
                                        labelId="chatBotType-label"
                                        id="chatBotType"
                                        value={settings.chatBotType}
                                        onChange={handleOnChangeSettings}
                                        label="chatBotType"
                                        name="chatBotType"
                                    >
                                        <MenuItem value={"text"}>
                                            {i18n.t(
                                                "settings.settings.chatBotType.options.text"
                                            )}
                                        </MenuItem>
                                        <MenuItem value={"button"}>
                                            {i18n.t(
                                                "settings.settings.chatBotType.options.button"
                                            )}
                                        </MenuItem>
                                        <MenuItem value={"list"}>
                                            {i18n.t(
                                                "settings.settings.chatBotType.options.list"
                                            )}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid> */}
                            <Grid item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                >
                                    <InputLabel id="viewMsg-label">
                                        {i18n.t(
                                            "settings.settings.viewMsg.name"
                                        )}
                                    </InputLabel>
                                    <Select
                                        labelId="viewMsg-label"
                                        id="viewMsg"
                                        value={settings.viewMsg}
                                        onChange={handleOnChangeSettings}
                                        label="viewMsg"
                                        name="viewMsg"
                                    >
                                        <MenuItem value={"disabled"}>
                                            {i18n.t(
                                                "settings.settings.viewMsg.options.disabled"
                                            )}
                                        </MenuItem>
                                        <MenuItem value={"accepted"}>
                                            {i18n.t(
                                                "settings.settings.viewMsg.options.accepted"
                                            )}
                                        </MenuItem>
                                        <MenuItem value={"all"}>
                                            {i18n.t(
                                                "settings.settings.viewMsg.options.all"
                                            )}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                >
                                    <InputLabel id="signMsg-label">
                                        {i18n.t(
                                            "settings.settings.signMsg.name"
                                        )}
                                    </InputLabel>
                                    <Select
                                        labelId="signMsg-label"
                                        id="signMsg"
                                        value={settings.signMsg}
                                        onChange={handleOnChangeSettings}
                                        label="signMsg"
                                        name="signMsg"
                                    >
                                        <MenuItem value={"disabled"}>
                                            {i18n.t(
                                                "settings.settings.signMsg.options.disabled"
                                            )}
                                        </MenuItem>
                                        <MenuItem value={"admin"}>
                                            {i18n.t(
                                                "settings.settings.signMsg.options.admin"
                                            )}
                                        </MenuItem>
                                        <MenuItem value={"all"}>
                                            {i18n.t(
                                                "settings.settings.signMsg.options.all"
                                            )}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSaveSettings}
                            style={{ marginTop: 10 }}
                        >
                            {i18n.t("settings.save")}
                        </Button>
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                >
                                    <InputLabel id="call-label">
                                        {i18n.t(
                                            "settings.settings.sendinblueApiKey"
                                        )}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="sendinblueApiKey"
                                        value={settings.sendinblueApiKey}
                                        onChange={handleOnChangeSettings}
                                        label={i18n.t(
                                            "settings.settings.sendinblueApiKey"
                                        )}
                                        name="sendinblueApiKey"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                >
                                    <InputLabel id="call-label">
                                        {i18n.t(
                                            "settings.settings.sendinblueEmail"
                                        )}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="sendinblueEmail"
                                        value={settings.sendinblueEmail}
                                        onChange={handleOnChangeSettings}
                                        label={i18n.t(
                                            "settings.settings.sendinblueEmail"
                                        )}
                                        name="sendinblueEmail"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                >
                                    <InputLabel id="call-label">
                                        {i18n.t(
                                            "settings.settings.sendinblueName"
                                        )}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="sendinblueName"
                                        value={settings.sendinblueName}
                                        onChange={handleOnChangeSettings}
                                        label={i18n.t(
                                            "settings.settings.sendinblueName"
                                        )}
                                        name="sendinblueName"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSaveSettings}
                            style={{ marginTop: 10 }}
                        >
                            {i18n.t("settings.save")}
                        </Button>
                    </TabPanel>
                    <TabPanel value={tabValue} index={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                >
                                    <InputLabel id="call-label">
                                        {i18n.t(
                                            "settings.settings.metabaseSiteUrl"
                                        )}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="metabaseSiteUrl"
                                        value={settings.metabaseSiteUrl}
                                        onChange={handleOnChangeSettings}
                                        label={i18n.t(
                                            "settings.settings.metabaseSiteUrl"
                                        )}
                                        name="metabaseSiteUrl"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                >
                                    <InputLabel id="call-label">
                                        {i18n.t(
                                            "settings.settings.metabaseSecretKey"
                                        )}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="metabaseSecretKey"
                                        value={settings.metabaseSecretKey}
                                        onChange={handleOnChangeSettings}
                                        label={i18n.t(
                                            "settings.settings.metabaseSecretKey"
                                        )}
                                        name="metabaseSecretKey"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSaveSettings}
                            style={{ marginTop: 10 }}
                        >
                            {i18n.t("settings.save")}
                        </Button>
                    </TabPanel>
                </Box>
            </Paper>
        </MainContainer>
    );
};

export default Settings;
