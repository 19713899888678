import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import MenuBookIcon from "@material-ui/icons/MenuBook";
// import {
//     AddCircleOutline,
//     ChatBubbleOutlineOutlined,
//     LibraryBooks,
// } from "@material-ui/icons";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import { Badge } from "@material-ui/core";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import EventIcon from "@material-ui/icons/Event";
import BuildIcon from "@material-ui/icons/Build";
import PeopleIcon from "@material-ui/icons/People";
import ListIcon from "@material-ui/icons/ListAlt";
import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";
import { makeStyles } from "@material-ui/core/styles";
import { isArray } from "lodash";
import { socketConnection } from "../services/socket";
import api from "../services/api";
import toastError from "../errors/toastError";
import ForumIcon from "@material-ui/icons/Forum";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import {
    getApiRest,
    getCampaign,
    getDialogFlowIntegration,
    getInternalChat,
    docUrl,
} from "../config";

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.primary.main,
        minWidth: "56px",
    },
    active: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
        "& .MuiListItemIcon-root": {
            color: theme.palette.primary.contrastText,
        },
    },
    customBadge: {
        backgroundColor: "#f44336",
        color: "#fff",
    },
}));

function ListItemLink(props) {
    const { icon, primary, to, className } = props;
    const classes = useStyles();

    const renderLink = React.useMemo(
        () =>
            React.forwardRef((itemProps, ref) => (
                <RouterLink to={to} ref={ref} {...itemProps} />
            )),
        [to]
    );

    return (
        <li>
            <ListItem button component={renderLink} className={className}>
                {icon ? (
                    <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
                ) : null}
                <ListItemText primary={primary} />
            </ListItem>
        </li>
    );
}

const reducer = (state, action) => {
    if (action.type === "LOAD_CHATS") {
        const chats = action.payload;
        const newChats = [];

        if (isArray(chats)) {
            chats.forEach((chat) => {
                const chatIndex = state.findIndex((u) => u.id === chat.id);
                if (chatIndex !== -1) {
                    state[chatIndex] = chat;
                } else {
                    newChats.push(chat);
                }
            });
        }

        return [...state, ...newChats];
    }

    if (action.type === "UPDATE_CHATS") {
        const chat = action.payload;
        const chatIndex = state.findIndex((u) => u.id === chat.id);

        if (chatIndex !== -1) {
            state[chatIndex] = chat;
            return [...state];
        } else {
            return [chat, ...state];
        }
    }

    if (action.type === "DELETE_CHAT") {
        const chatId = action.payload;

        const chatIndex = state.findIndex((u) => u.id === chatId);
        if (chatIndex !== -1) {
            state.splice(chatIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }

    if (action.type === "CHANGE_CHAT") {
        const changedChats = state.map((chat) => {
            if (chat.id === action.payload.chat.id) {
                return action.payload.chat;
            }
            return chat;
        });
        return changedChats;
    }
};

const MainListItems = (props) => {
    const classes = useStyles();
    const { drawerClose } = props;
    const { whatsApps } = useContext(WhatsAppsContext);
    const { user } = useContext(AuthContext);
    const [connectionWarning, setConnectionWarning] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam] = useState("");
    const [chats, dispatch] = useReducer(reducer, []);
    const [invisible, setInvisible] = useState(true);

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchChats();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam, pageNumber]);

    useEffect(() => {
        const companyId = localStorage.getItem("companyId");
        const socket = socketConnection({ companyId });

        socket.on(`company-${companyId}-chat`, (data) => {
            if (data.action === "new-message") {
                dispatch({ type: "CHANGE_CHAT", payload: data });
            }
            if (data.action === "update") {
                dispatch({ type: "CHANGE_CHAT", payload: data });
            }
        });
        return () => {
            socket.disconnect();
        };
    }, [user]);

    useEffect(() => {
        let unreadsCount = 0;
        if (chats.length > 0) {
            for (let chat of chats) {
                for (let chatUser of chat.users) {
                    if (chatUser.userId === user.id) {
                        unreadsCount += chatUser.unreads;
                    }
                }
            }
        }
        if (unreadsCount > 0) {
            setInvisible(false);
        } else {
            setInvisible(true);
        }
    }, [chats, user.id]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (whatsApps.length > 0) {
                const offlineWhats = whatsApps.filter((whats) => {
                    return (
                        whats.status === "qrcode" ||
                        whats.status === "PAIRING" ||
                        whats.status === "DISCONNECTED" ||
                        whats.status === "TIMEOUT" ||
                        whats.status === "OPENING"
                    );
                });
                if (offlineWhats.length > 0) {
                    setConnectionWarning(true);
                } else {
                    setConnectionWarning(false);
                }
            }
        }, 2000);
        return () => clearTimeout(delayDebounceFn);
    }, [whatsApps]);

    const fetchChats = async () => {
        try {
            const { data } = await api.get("/chats/", {
                params: { searchParam, pageNumber },
            });
            dispatch({ type: "LOAD_CHATS", payload: data.records });
        } catch (err) {
            toastError(err);
        }
    };

    return (
        <div onClick={drawerClose}>
            <ListItemLink
                to="/"
                primary="Dashboard"
                icon={<DashboardOutlinedIcon />}
                className={
                    window.location.pathname === "/" ? classes.active : ""
                }
            />
            <ListItemLink
                to="/tickets"
                primary={"Atendimentos"}
                icon={<WhatsAppIcon />}
                className={
                    window.location.pathname.includes("/tickets")
                        ? classes.active
                        : ""
                }
            />

            <ListItemLink
                to="/contacts"
                primary={i18n.t("mainDrawer.listItems.contacts")}
                icon={<ContactPhoneOutlinedIcon />}
                className={
                    window.location.pathname === "/contacts"
                        ? classes.active
                        : ""
                }
            />
            <ListItemLink
                to="/quickAnswers"
                primary={i18n.t("mainDrawer.listItems.quickAnswers")}
                icon={<QuestionAnswerOutlinedIcon />}
                className={
                    window.location.pathname === "/quickAnswers"
                        ? classes.active
                        : ""
                }
            />
            <ListItemLink
                to="/schedules"
                primary={i18n.t("mainDrawer.listItems.schedules")}
                icon={<EventIcon />}
                className={
                    window.location.pathname === "/schedules"
                        ? classes.active
                        : ""
                }
            />
            <ListItemLink
                to="/tags"
                primary={i18n.t("mainDrawer.listItems.tags")}
                icon={<LocalOfferIcon />}
                className={
                    window.location.pathname === "/tags" ? classes.active : ""
                }
            />

            {getInternalChat() === 1 && (
                <ListItemLink
                    to="/chats"
                    primary={i18n.t("mainDrawer.listItems.internalChat")}
                    className={
                        window.location.pathname === "/chats"
                            ? classes.active
                            : ""
                    }
                    icon={
                        <Badge
                            classes={{ badge: classes.customBadge }}
                            variant="dot"
                            invisible={invisible}
                        >
                            <ForumIcon />
                        </Badge>
                    }
                />
            )}

            <ListItemLink
                to="/helps"
                primary={i18n.t("mainDrawer.listItems.helps")}
                icon={<HelpOutlineIcon />}
            />
            <Can
                role={user.profile}
                perform="drawer-admin-items:view"
                yes={() => (
                    <>
                        {getCampaign() === 1 && (
                            <>
                                <Divider />
                                <ListSubheader inset>
                                    {i18n.t(
                                        "mainDrawer.listItems.campaignsManager"
                                    )}
                                </ListSubheader>

                                <ListItemLink
                                    to="/campaigns"
                                    primary={i18n.t(
                                        "mainDrawer.listItems.campaigns"
                                    )}
                                    icon={<ListIcon />}
                                    className={
                                        window.location.pathname ===
                                            "/campaigns" ||
                                        (window.location.pathname.includes(
                                            "/campaign"
                                        ) &&
                                            window.location.pathname.includes(
                                                "/report"
                                            ))
                                            ? classes.active
                                            : ""
                                    }
                                />
                                <ListItemLink
                                    to="/contact-lists"
                                    primary={i18n.t(
                                        "mainDrawer.listItems.contactLists"
                                    )}
                                    icon={<PeopleIcon />}
                                    className={
                                        window.location.pathname ===
                                            "/contact-lists" ||
                                        (window.location.pathname.includes(
                                            "/contact-lists"
                                        ) &&
                                            window.location.pathname.includes(
                                                "/contacts"
                                            ))
                                            ? classes.active
                                            : ""
                                    }
                                />
                                <ListItemLink
                                    to="/campaigns-config"
                                    primary={i18n.t(
                                        "mainDrawer.listItems.campaignsConfig"
                                    )}
                                    icon={<SettingsOutlinedIcon />}
                                    className={
                                        window.location.pathname ===
                                        "/campaigns-config"
                                            ? classes.active
                                            : ""
                                    }
                                />
                            </>
                        )}
                        <Divider />
                        <ListSubheader inset>
                            {i18n.t("mainDrawer.listItems.administration")}
                        </ListSubheader>
                        <ListItemLink
                            to="/connections"
                            primary={i18n.t("mainDrawer.listItems.connections")}
                            icon={
                                <Badge
                                    overlap="rectangular"
                                    badgeContent={connectionWarning ? "!" : 0}
                                    color="error"
                                >
                                    <SyncAltIcon />
                                </Badge>
                            }
                            className={
                                window.location.pathname === "/connections"
                                    ? classes.active
                                    : ""
                            }
                        />
                        <ListItemLink
                            to="/users"
                            primary={i18n.t("mainDrawer.listItems.users")}
                            icon={<PeopleAltOutlinedIcon />}
                            className={
                                window.location.pathname === "/users"
                                    ? classes.active
                                    : ""
                            }
                        />
                        <ListItemLink
                            to="/subscriptions"
                            primary={i18n.t(
                                "mainDrawer.listItems.subscriptions"
                            )}
                            icon={<MonetizationOnIcon />}
                            className={
                                window.location.pathname === "/subscriptions"
                                    ? classes.active
                                    : ""
                            }
                        />
                        <ListItemLink
                            to="/queues"
                            primary={i18n.t("mainDrawer.listItems.queues")}
                            icon={<AccountTreeOutlinedIcon />}
                            className={
                                window.location.pathname === "/queues"
                                    ? classes.active
                                    : ""
                            }
                        />
                        {getDialogFlowIntegration() === 1 && (
                            <ListItemLink
                                to="/DialogFlowTemplateBuilder"
                                primary={i18n.t(
                                    "mainDrawer.listItems.dialogFlowTemplateBuilder"
                                )}
                                icon={<BuildIcon />}
                                className={
                                    window.location.pathname ===
                                    "/DialogFlowTemplateBuilder"
                                        ? classes.active
                                        : ""
                                }
                            />
                        )}
                        <ListItemLink
                            to="/settings"
                            primary={i18n.t("mainDrawer.listItems.settings")}
                            icon={<SettingsOutlinedIcon />}
                            className={
                                window.location.pathname === "/settings"
                                    ? classes.active
                                    : ""
                            }
                        />
                        {getApiRest() === 1 && (
                            <>
                                <Divider />
                                <ListSubheader inset>
                                    {i18n.t("mainDrawer.listItems.api")}
                                </ListSubheader>

                                <ListItemLink
                                    to="/tokens"
                                    primary={i18n.t(
                                        "mainDrawer.listItems.tokens"
                                    )}
                                    icon={<VpnKeyIcon />}
                                    className={
                                        window.location.pathname === "/tokens"
                                            ? classes.active
                                            : ""
                                    }
                                />
                                <ListItem
                                    button
                                    component="a"
                                    href={docUrl()}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <ListItemIcon>
                                        <MenuBookIcon color="primary" />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={i18n.t(
                                            "mainDrawer.listItems.apiDocs"
                                        )}
                                    />
                                </ListItem>
                            </>
                        )}
                    </>
                )}
            />
        </div>
    );
};

export default MainListItems;
