import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { CodeBlock, dracula } from "react-code-blocks";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(2),
    },

    paper: {
        padding: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        marginBottom: 12,
    },

    settingOption: {
        marginLeft: "auto",
    },

    margin: {
        margin: theme.spacing(1),
    },

    color: {
        color: "#7bc780",
    },

    text: {
        marginLeft: "42px",
    },

    textP: {
        marginLeft: "42px",
    },
}));

const Api = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Container>
                <h2>Documentação para envio de mensagens</h2>
                <h2 className={classes.color}>Métodos de Envio</h2>
                <p className={classes.text}>1. Enviar Mensagens de Texto</p>
                <p className={classes.text}>2. Enviar Imagem</p>
                <p className={classes.text}>3. Enviar Vídeo</p>
                <p className={classes.text}>4. Enviar Áudio</p>
                <p className={classes.text}>5. Enviar Documentos</p>
                <p className={classes.text}>6. Enviar Mídia via URL</p>
                <p className={classes.text}>7. Enviar Botões Simples</p>
                <p className={classes.text}>8. Enviar Botões Template</p>
                <p className={classes.text}>9. Enviar Lista</p>
                <p className={classes.text}>10. Enviar Localização</p>
                <p className={classes.text}>11. Listar conexões</p>
                <p className={classes.text}>12. Listar departamentos</p>
                <p className={classes.text}>13. Pegar dados do Ticket</p>
                <p className={classes.text}>14. Atualizar Ticket</p>
                <p className={classes.text}>15. Validar Contatos</p>
                <h2 className={classes.color}>Instruções</h2>
                <p>
                    <b>Observações Importantes</b>
                </p>
                <ul>
                    <li>
                        Para pegar o token da API, vá em TOKENS que seu token
                        estará la, sem ele não será possível enviar mensagens.
                    </li>
                    <li>
                        O número para envio não deve ter mascara ou caracteres
                        especiais e deve ser composto por:
                    </li>
                    <br />
                    <ol>
                        <ul>
                            <li>Código do pais - Ex: 55 (Brasil)</li>
                            <li>DDD</li>
                            <li>Número</li>
                        </ul>
                    </ol>
                </ul>
                <h2 className={classes.color}>1. Enviar Mensagens de Texto</h2>
                <p>
                    Seguem abaixo lista de informações necessárias para envio
                    das mensagens de texto:
                </p>
                <p className={classes.textP}>
                    <b>URL: </b>
                    <CodeBlock
                        text={`${process.env.REACT_APP_BACKEND_URL}/api/messages/text`}
                        language={"text"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <p className={classes.textP}>
                    <b>Método: </b>POST
                </p>
                <p className={classes.textP}>
                    <b>Headers: </b>
                    <CodeBlock
                        text={`{
    Authorization: "Bearer (token)",
    Content-Type: application/json
}`}
                        language={"json"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <p className={classes.textP}>
                    <b>Body: </b>
                    <CodeBlock
                        text={`{
    "number": "5511999999999",
    "message": "Mensagem de teste",
    "whatsappId": "7fe9d170-06c6-4b18-8853-225d00fd848d",
    "autoClose": true
}`}
                        language={"json"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <h2 className={classes.color}>2. Enviar Imagem</h2>
                <p>
                    Seguem abaixo lista de informações necessárias para envio de
                    imagens:
                </p>
                <p className={classes.textP}>
                    <b>URL: </b>
                    <CodeBlock
                        text={`${process.env.REACT_APP_BACKEND_URL}/api/messages/image`}
                        language={"text"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <p className={classes.textP}>
                    <b>Método: </b>POST
                </p>
                <p className={classes.textP}>
                    <b>Headers: </b>
                    <CodeBlock
                        text={`{\n    Authorization: "Bearer (token)", \n    Content-Type: multipart/form-data\n}`}
                        language={"json"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <p className={classes.textP}>
                    <b>Body: </b>
                    <CodeBlock
                        text={`{
    "number": "5511999999999",
    "images": "enviar seu arquivo de imagem JPG ou PNG",
    "message": "Mensagem de teste",
    "whatsappId": "7fe9d170-06c6-4b18-8853-225d00fd848d",
    "autoClose": true
}`}
                        language={"json"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <h2 className={classes.color}>3. Enviar Vídeo</h2>
                <p>
                    Seguem abaixo lista de informações necessárias para envio de
                    vídeos:
                </p>
                <p className={classes.textP}>
                    <b>URL: </b>
                    <CodeBlock
                        text={`${process.env.REACT_APP_BACKEND_URL}/api/messages/video`}
                        language={"text"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <p className={classes.textP}>
                    <b>Método: </b>POST
                </p>
                <p className={classes.textP}>
                    <b>Headers: </b>
                    <CodeBlock
                        text={`{\n    Authorization: "Bearer (token)", \n    Content-Type: multipart/form-data\n}`}
                        language={"json"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <p className={classes.textP}>
                    <b>Body: </b>
                    <CodeBlock
                        text={`{
    "number": "5511999999999",
    "videos": "enviar seu arquivo de video MP4",
    "message": "Mensagem de teste",
    "whatsappId": "7fe9d170-06c6-4b18-8853-225d00fd848d",
    "autoClose": true
}`}
                        language={"json"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <h2 className={classes.color}>4. Enviar Áudio</h2>
                <p>
                    Seguem abaixo lista de informações necessárias para envio de
                    áudios:
                </p>
                <p className={classes.textP}>
                    <b>URL: </b>
                    <CodeBlock
                        text={`${process.env.REACT_APP_BACKEND_URL}/api/messages/audio`}
                        language={"text"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <p className={classes.textP}>
                    <b>Método: </b>POST
                </p>
                <p className={classes.textP}>
                    <b>Headers: </b>
                    <CodeBlock
                        text={`{\n    Authorization: "Bearer (token)", \n    Content-Type: multipart/form-data\n}`}
                        language={"json"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <p className={classes.textP}>
                    <b>Body: </b>
                    <CodeBlock
                        text={`{
    "number": "5511999999999",
    "audios": "enviar seu arquivo de áudio",
    "message": "Mensagem de teste",
    "whatsappId": "7fe9d170-06c6-4b18-8853-225d00fd848d",
    "autoClose": true
}`}
                        language={"json"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <h2 className={classes.color}>5. Enviar Documento</h2>
                <p>
                    Seguem abaixo lista de informações necessárias para envio de
                    documentos:
                </p>
                <p className={classes.textP}>
                    <b>URL: </b>
                    <CodeBlock
                        text={`${process.env.REACT_APP_BACKEND_URL}/api/messages/doc`}
                        language={"text"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <p className={classes.textP}>
                    <b>Método: </b>POST
                </p>
                <p className={classes.textP}>
                    <b>Headers: </b>
                    <CodeBlock
                        text={`{\n    Authorization: "Bearer (token)", \n    Content-Type: multipart/form-data\n}`}
                        language={"json"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <p className={classes.textP}>
                    <b>Body: </b>
                    <CodeBlock
                        text={`{\n
    "number": "5511999999999",
    "docs": "enviar seu documento como PDF, XLSX, etc...",
    "message": "Mensagem de teste",
    "whatsappId": "7fe9d170-06c6-4b18-8853-225d00fd848d",
    "autoClose": true
}`}
                        language={"json"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <h2 className={classes.color}>6. Enviar Mídia via URL</h2>
                <p>
                    Seguem abaixo lista de informações necessárias para envio de
                    mídia por url:
                </p>
                <p className={classes.textP}>
                    <b>URL: </b>
                    <CodeBlock
                        text={`${process.env.REACT_APP_BACKEND_URL}/api/messages/mediaUrl`}
                        language={"text"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <p className={classes.textP}>
                    <b>Método: </b>POST
                </p>
                <p className={classes.textP}>
                    <b>Headers: </b>
                    <CodeBlock
                        text={`{
    Authorization: "Bearer (token)",
    Content-Type: application/json
}`}
                        language={"json"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <p className={classes.textP}>
                    <b>Body: </b>
                    <CodeBlock
                        text={`{
    "number": "5511999999999",
    "url": "https://example.com/image.jpg",
    "type": "image",
    "mimeType": "image/jpeg",
    "filename": "image.jpeg",
    "message": "Mensagem de Teste",
    "whatsappId": "7fe9d170-06c6-4b18-8853-225d00fd848d",
    "autoClose": true
}`}
                        language={"json"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <p className={classes.textP}>
                    <b>type:</b> [image, video, audio, document]
                </p>
                <p className={classes.textP}>
                    <b>mimeType:</b> Verifique tipos mime comuns em
                    https://mzl.la/3si3and
                </p>
                <h2 className={classes.color}>7. Enviar Botões Simples</h2>
                <p>
                    Seguem abaixo lista de informações necessárias para envio de
                    botões simples:
                </p>
                <p className={classes.textP}>
                    <b>URL: </b>
                    <CodeBlock
                        text={`${process.env.REACT_APP_BACKEND_URL}/api/messages/simpleButton`}
                        language={"text"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <p className={classes.textP}>
                    <b>Método: </b>POST
                </p>
                <p className={classes.textP}>
                    <b>Headers: </b>
                    <CodeBlock
                        text={`{
    Authorization: "Bearer (token)",
    Content-Type: application/json
}`}
                        language={"json"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <p className={classes.textP}>
                    <b>Body: </b>
                    <CodeBlock
                        text={`{
    "number": "5511999999999",
    "btndata": {
        "text": "Title and free text for Simple Buttons",
        "buttons": [
            {
                "buttonId": "id1",
                "buttonText": {
                    "displayText": "Simple Button 1️⃣"
                }
            },
            {
                "buttonId": "id2",
                "buttonText": {
                    "displayText": "Simple Button 2️⃣"
                }
            },
            {
                "buttonId": "id3",
                "buttonText": {
                    "displayText": "Simple Button 3️⃣"
                }
            }
        ],
        "footer": "Footer text"
    },
    "whatsappId": "7fe9d170-06c6-4b18-8853-225d00fd848d",
    "autoClose": true
}`}
                        language={"json"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <h2 className={classes.color}>8. Enviar Botões Template</h2>
                <p>
                    Seguem abaixo lista de informações necessárias para envio de
                    botões template:
                </p>
                <p className={classes.textP}>
                    <b>URL: </b>
                    <CodeBlock
                        text={`${process.env.REACT_APP_BACKEND_URL}/api/messages/templateButton`}
                        language={"text"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <p className={classes.textP}>
                    <b>Método: </b>POST
                </p>
                <p className={classes.textP}>
                    <b>Headers: </b>
                    <CodeBlock
                        text={`{
    Authorization: "Bearer (token)",
    Content-Type: application/json
}`}
                        language={"json"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <p className={classes.textP}>
                    <b>Body: </b>
                    <CodeBlock
                        text={`{
    "number": "5511999999999",
    "btndata": {
        "text": "Title and free text for Template Buttons",
        "buttons": [
            {
                "index": "0",
                "type": "replyButton",
                "title": "↩️ Reply Button",
                "id": "id1"
            },
            {
                "index": "1",
                "type": "urlButton",
                "title": "Url Button",
                "payload": "https://agenciadgcode.com"
            },
            {
                "index": "2",
                "type": "callButton",
                "title": "Call Button",
                "payload": "5511999999999"
            }
        ],
        "footerText": "Footer Text"
    },
    "whatsappId": "7fe9d170-06c6-4b18-8853-225d00fd848d",
    "autoClose": true
}`}
                        language={"json"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <h2 className={classes.color}>9. Enviar Lista</h2>
                <p>
                    Seguem abaixo lista de informações necessárias para envio de
                    lista:
                </p>
                <p className={classes.textP}>
                    <b>URL: </b>
                    <CodeBlock
                        text={`${process.env.REACT_APP_BACKEND_URL}/api/messages/list`}
                        language={"text"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <p className={classes.textP}>
                    <b>Método: </b>POST
                </p>
                <p className={classes.textP}>
                    <b>Headers: </b>
                    <CodeBlock
                        text={`{
    Authorization: "Bearer (token)",
    Content-Type: application/json
}`}
                        language={"json"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <p className={classes.textP}>
                    <b>Body: </b>
                    <CodeBlock
                        text={`{
    "number": "5511999999999",
    "msgdata": {
        "buttonText": "Button Text",
        "text": "Middle Text",
        "title": "Head Title",
        "description": "Footer Description",
        "sections": [
            {
                "title": "Title Section 1",
                "rows": [
                    {
                        "title": "Title Option 1",
                        "description": "Option Description 1",
                        "rowId": "string11"
                    },
                    {
                        "title": "Title Option 2",
                        "description": "Option Description 2",
                        "rowId": "string12"
                    }
                ]
            },
            {
                "title": "Title Section 2",
                "rows": [
                    {
                        "title": "Title Option 1",
                        "description": "Option Description",
                        "rowId": "string21"
                    }
                ]
            }
        ],
        "listType": 0
    },
    "whatsappId": "7fe9d170-06c6-4b18-8853-225d00fd848d",
    "autoClose": true
}`}
                        language={"json"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <h2 className={classes.color}>10. Enviar Localização</h2>
                <p>
                    Seguem abaixo lista de informações necessárias para envio de
                    localização:
                </p>
                <p className={classes.textP}>
                    <b>URL: </b>
                    <CodeBlock
                        text={`${process.env.REACT_APP_BACKEND_URL}/api/messages/location`}
                        language={"text"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <p className={classes.textP}>
                    <b>Método: </b>POST
                </p>
                <p className={classes.textP}>
                    <b>Headers: </b>
                    <CodeBlock
                        text={`{
    Authorization: "Bearer (token)",
    Content-Type: application/json
}`}
                        language={"json"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <p className={classes.textP}>
                    <b>Body: </b>
                    <CodeBlock
                        text={`{
    "number": "5511999999999",
    "locdata": {
        "latitude": 25.65239387554923,
        "longitude": 51.487879683998834,
        "name": "Al Bayt Stadium",
        "address": "2022 World Cup Opening Venue in Qatar"
    },
    "whatsappId": "7fe9d170-06c6-4b18-8853-225d00fd848d",
    "autoClose": true
}`}
                        language={"json"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <h2 className={classes.color}>11. Listar todos as conexões</h2>
                <p>
                    Seguem abaixo lista de informações necessárias para trazer
                    todos as conexões:
                </p>
                <p className={classes.textP}>
                    <b>URL: </b>
                    <CodeBlock
                        text={`${process.env.REACT_APP_BACKEND_URL}/api/tickets/connections`}
                        language={"text"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <p className={classes.textP}>
                    <b>Método: </b>GET
                </p>
                <p className={classes.textP}>
                    <b>Headers: </b>
                    <CodeBlock
                        text={`{
    Authorization: "Bearer (token)",
    Content-Type: application/json
}`}
                        language={"json"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <h2 className={classes.color}>
                    12. Listar todos os departamentos
                </h2>
                <p>
                    Seguem abaixo lista de informações necessárias para trazer
                    todos os departamentos:
                </p>
                <p className={classes.textP}>
                    <b>URL: </b>
                    <CodeBlock
                        text={`${process.env.REACT_APP_BACKEND_URL}/api/tickets/queues`}
                        language={"text"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <p className={classes.textP}>
                    <b>Método: </b>GET
                </p>
                <p className={classes.textP}>
                    <b>Headers: </b>
                    <CodeBlock
                        text={`{
    Authorization: "Bearer (token)",
    Content-Type: application/json
}`}
                        language={"json"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <h2 className={classes.color}>13. Pegar dados do ticket</h2>
                <p>
                    Seguem abaixo lista de informações necessárias para trazer
                    as informações do ticket:
                </p>
                <p className={classes.textP}>
                    <b>URL: </b>
                    <CodeBlock
                        text={`${process.env.REACT_APP_BACKEND_URL}/api/tickets/get`}
                        language={"text"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <p className={classes.textP}>
                    <b>Método: </b>GET
                </p>
                <p className={classes.textP}>
                    <b>Headers: </b>
                    <CodeBlock
                        text={`{
    Authorization: "Bearer (token)",
    Content-Type: application/json
}`}
                        language={"json"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <p className={classes.textP}>
                    <b>Body: </b>
                    <CodeBlock
                        text={`{
    "ticketId": "7fe9d170-06c6-4b18-8853-225d00fd848d"
}`}
                        language={"json"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <h2 className={classes.color}>
                    14. Atualizar Informações do Ticket
                </h2>
                <p>
                    Seguem abaixo lista de informações necessárias para
                    atualizar informações do ticket:
                </p>
                <p className={classes.textP}>
                    <b>URL: </b>
                    <CodeBlock
                        text={`${process.env.REACT_APP_BACKEND_URL}/api/tickets/update`}
                        language={"text"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <p className={classes.textP}>
                    <b>Método: </b>POST
                </p>
                <p className={classes.textP}>
                    <b>Headers: </b>
                    <CodeBlock
                        text={`{
    Authorization: "Bearer (token)",
    Content-Type: application/json
}`}
                        language={"json"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <p className={classes.textP}>
                    <b>Body: </b>
                    <CodeBlock
                        text={`{
    "ticketId": "7fe9d170-06c6-4b18-8853-225d00fd848d",
    "queueId": "7fe9d170-06c6-4b18-8853-225d00fd848d",
    "userId": "7fe9d170-06c6-4b18-8853-225d00fd848d",
    "status": "pending",
    "whatsappId": "7fe9d170-06c6-4b18-8853-225d00fd848d"
}`}
                        language={"json"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <p className={classes.textP}>
                    <b>status:</b> [pending, open, closed]
                </p>
                <h2 className={classes.color}>15. Validar Contatos</h2>
                <p>
                    Seguem abaixo lista de informações necessárias para validar
                    contatos:
                </p>
                <p className={classes.textP}>
                    <b>URL: </b>
                    <CodeBlock
                        text={`${process.env.REACT_APP_BACKEND_URL}/api/tickets/validateContact?number=5511999999999`}
                        language={"text"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
                <p className={classes.textP}>
                    <b>Método: </b>GET
                </p>
                <p className={classes.textP}>
                    <b>Headers: </b>
                    <CodeBlock
                        text={`{
    Authorization: "Bearer (token)",
    Content-Type: application/json
}`}
                        language={"json"}
                        showLineNumbers={false}
                        theme={dracula}
                    />
                </p>
            </Container>
        </div>
    );
};

export default Api;
