import React, { useState, useReducer, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
    TableBody,
    TableRow,
    TableCell,
    Table,
    TableHead,
    Paper,
    Button,
} from "@material-ui/core";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import TableRowSkeleton from "../../components/TableRowSkeleton";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";

import { isArray } from "lodash";

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    customTableCell: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: theme.typography.pxToRem(14),
        border: "1px solid #dadde9",
        maxWidth: 450,
    },
    tooltipPopper: {
        textAlign: "center",
    },
    buttonProgress: {
        color: green[500],
    },
}));

const reducer = (state, action) => {
    if (action.type === "LOAD_SUBSCRIPTIONS") {
        const subscriptions = action.payload;
        const newSubscription = [];

        if (isArray(subscriptions)) {
            subscriptions.forEach((subscription) => {
                const subscriptionIndex = state.findIndex(
                    (u) => u.id === subscription.id
                );
                if (subscriptionIndex !== -1) {
                    state[subscriptionIndex] = subscription;
                } else {
                    newSubscription.push(subscription);
                }
            });
        }

        return [...state, ...newSubscription];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const Subscriptions = () => {
    const classes = useStyles();

    const [subscriptions, dispatch] = useReducer(reducer, []);
    const [loading, setLoading] = useState(false);
    const [, /*hasMore*/ setHasMore] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
        const fetchSubscriptions = async () => {
            try {
                const { data } = await api.get("/subscriptions", {
                    params: { pageNumber },
                });

                dispatch({ type: "LOAD_SUBSCRIPTIONS", payload: data.records });
                setHasMore(data.hasMore);
                setLoading(false);
            } catch (err) {
                toastError(err);
            }
        };

        dispatch({ type: "RESET" });
        setPageNumber(1);

        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            fetchSubscriptions();
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [pageNumber]);

    const formatDate = (date) => {
        const day = date.split("-")[2].split("T")[0];
        const month = date.split("-")[1];
        const year = date.split("-")[0];

        return `${day}/${month}/${year}`;
    };

    const renderCycle = (cycle) => {
        switch (cycle) {
            case "MONTHLY":
                return i18n.t("subscriptions.cycle.monthly");
            case "YEARLY":
                return i18n.t("subscriptions.cycle.yearly");
            default:
                return cycle;
        }
    };

    const renderBillingType = (billingType) => {
        switch (billingType) {
            case "CREDIT_CARD":
                return i18n.t("subscriptions.billingType.creditCard");
            case "BOLETO":
                return i18n.t("subscriptions.billingType.boleto");
            case "PIX":
                return i18n.t("subscriptions.billingType.pix");
            case "UNDEFINED":
                return i18n.t("subscriptions.billingType.undefined");
            default:
                return billingType;
        }
    };

    const renderUrlButton = (url, status) => {
        if (status === "PENDING") {
            return (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    onClick={() => window.open(url, "_blank")}
                >
                    {i18n.t("subscriptions.table.invoiceUrl")}
                </Button>
            );
        } else {
            return <CheckCircleIcon style={{ color: green[500] }} />;
        }
    };

    const renderValueBr = (value) => {
        return value.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
        });
    };

    return (
        <MainContainer>
            <MainHeader>
                <Title>{i18n.t("subscriptions.title")}</Title>
            </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                {i18n.t("subscriptions.table.dateCreated")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("subscriptions.table.nextDueDate")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("subscriptions.table.cycle")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("subscriptions.table.description")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("subscriptions.table.billingType")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("subscriptions.table.value")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("subscriptions.table.invoiceUrl")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRowSkeleton />
                        ) : (
                            <>
                                {subscriptions?.length > 0 &&
                                    subscriptions.map((subscription) => (
                                        <TableRow key={subscription.id}>
                                            <TableCell align="center">
                                                {formatDate(
                                                    subscription.dateCreated
                                                )}
                                            </TableCell>
                                            <TableCell align="center">
                                                {formatDate(
                                                    subscription.endDate
                                                )}
                                            </TableCell>
                                            <TableCell align="center">
                                                {renderCycle(
                                                    subscription.cycle
                                                )}
                                            </TableCell>
                                            <TableCell align="center">
                                                {subscription.description}
                                            </TableCell>
                                            <TableCell align="center">
                                                {renderBillingType(
                                                    subscription.billingType
                                                )}
                                            </TableCell>
                                            <TableCell align="center">
                                                {renderValueBr(
                                                    subscription.value
                                                )}
                                            </TableCell>
                                            <TableCell align="center">
                                                {renderUrlButton(
                                                    subscription.invoiceUrl,
                                                    subscription.status
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </>
                        )}
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default Subscriptions;
