import { useState, useEffect } from "react";
import toastError from "../../errors/toastError";

import api from "../../services/api";

const useMetabase = ({ user, theme }) => {
    const [loading, setLoading] = useState(true);
    const [iframeUrl, setIframeUrl] = useState("");

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchMetabase = async () => {
                try {
                    const { data } = await api.get("/dashboard/link", {
                        params: {
                            user,
                            theme,
                        },
                    });
                    setIframeUrl(data.iframeUrl);
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    toastError(err);
                }
            };

            fetchMetabase();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [user, theme]);

    return { iframeUrl, loading };
};

export default useMetabase;
