import { useState, useEffect } from "react";
import toastError from "../../errors/toastError";

import api from "../../services/api";

const useSettings = () => {
    const [loading, setLoading] = useState(true);
    const [call, setCall] = useState("");
    const [chatBotType, setChatBotType] = useState("");
    const [checkMsgIsGroup, setCheckMsgIsGroup] = useState("");
    const [ticketNoQueue, setTicketNoQueue] = useState("");
    const [timeCreateNewTicket, setTimeCreateNewTicket] = useState("");
    const [userApiToken, setUserApiToken] = useState("");
    const [userCreation, setUserCreation] = useState("");
    const [userRating, setUserRating] = useState("");
    const [viewMsg, setViewMsg] = useState("");
    const [signMsg, setSignMsg] = useState("");
    const [count, setCount] = useState(0);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchSettings = async () => {
                try {
                    const { data } = await api.get("/settings", {});

                    const dataSort = data.sort((a, b) => a.id - b.id);

                    setChatBotType(dataSort[0].value);
                    setUserCreation(dataSort[1].value);
                    setUserApiToken(dataSort[2].value);
                    setCall(dataSort[3].value);
                    setCheckMsgIsGroup(dataSort[4].value);
                    setTimeCreateNewTicket(dataSort[5].value);
                    setTicketNoQueue(dataSort[6].value);
                    setUserRating(dataSort[7].value);
                    setSignMsg(dataSort[8].value);
                    setViewMsg(dataSort[9].value);
                    setCount(data.count);
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    toastError(err);
                }
            };

            fetchSettings();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, []);

    return {
        call,
        chatBotType,
        ticketNoQueue,
        checkMsgIsGroup,
        timeCreateNewTicket,
        userApiToken,
        userCreation,
        userRating,
        viewMsg,
        signMsg,
        loading,
        count,
    };
};

export default useSettings;
